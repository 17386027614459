<div class="filter-container">
  <app-filter-bar
    menuView="true"
    [initialValue]="filters"
    [filters]="filtersConfig"
    [style]="windowWidth > 768 ? 'bordered-filters' : 'mobile'"
    [useInactiveUsersFiler]="true"
    (filterChanges)="updateFilters($event)"
  ></app-filter-bar>

  <div fxLayout="row" fxLayoutGap="16px" *ngIf='windowWidth > 768'>
    <button
      class="action-btn"
      mat-raised-button
      color="primary"
      [disabled]="!plannerTable.selectedRows.length"
      (click)="plannerTable.addUsersToSelectedTasks()"
    >
      <span>{{'ASSIGN_USERS' | translate}} ({{ plannerTable.selectedRows.length }})</span>
    </button>

    <button class="action-btn" mat-raised-button color="primary" (click)="openTaskBuckets()">
      <span>{{'TASK_BUCKETS' | translate}}</span>
    </button>

    <button class="action-btn" mat-raised-button color="accent" (click)="openNewTaskDialog()">
      <mat-icon>add</mat-icon>
      <span>{{'NEW_TASK' | translate}}</span>
    </button>
  </div>
</div>

<div class="table-container">
  <app-planner-table
    [filters]='filterObservable | async'
    (selectItem)='openNewTaskDialog($event)'
    #plannerTable
  ></app-planner-table>
</div>

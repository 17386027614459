
<form [formGroup]="filterForm" [ngClass]="style">
  <div fxLayout="row" fxLayoutGap="8px">
    <mat-form-field *ngIf="filterConfigured('search')" class="search-container">
      <mat-icon matPrefix inline >search</mat-icon>
      <input matInput formControlName="search" placeholder="{{'SEARCH' | translate }}">

        <mat-icon
          *ngIf="totalAppliedFilters > 0 && menuView"
          matBadgeSize="small"
          matBadgeColor="accent"
          [matBadge]="totalAppliedFilters"
          style="cursor: pointer"
          matSuffix
          inline
          [matMenuTriggerFor]="menu">
          tune
        </mat-icon>

        <mat-icon *ngIf="!(totalAppliedFilters > 0) && menuView" style="cursor: pointer" matSuffix [matMenuTriggerFor]="menu">
          tune
        </mat-icon>

      <button mat-icon-button matSuffix *ngIf="searchInput?.dirty">
        <mat-icon inline="true" (click)="clearFilter('search')" matSuffix>close</mat-icon>
      </button>
    </mat-form-field>


    <ng-container *ngIf="!menuView">

      <ng-container *ngTemplateOutlet="regionTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="statusesTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="selectionStatusesTemplate"></ng-container>

      <div *ngIf="filterConfigured('dateRange')" fxLayout="row" fxLayoutAlign="start center" fxFlex="300px">
        <ng-container *ngTemplateOutlet="dateRangeTemplate"></ng-container>
      </div>

      <div *ngIf="filterConfigured('intensity')" fxLayout="row" fxLayoutAlign="start center">
        <ng-container *ngTemplateOutlet="intensityTemplate"></ng-container>
      </div>

      <div *ngIf="filterConfigured('duration')" fxLayout="row" fxLayoutAlign="start center">
        <ng-container *ngTemplateOutlet="durationTemplate"></ng-container>
      </div>

      <div *ngIf="filterConfigured('transport')" fxLayout="row" fxLayoutAlign="start center">
        <ng-container *ngTemplateOutlet="transportTemplate"></ng-container>
      </div>

      <div *ngIf="filterConfigured('perspectives')" fxLayout="row" fxLayoutAlign="start center">
        <ng-container *ngTemplateOutlet="perspectivesTemplate"></ng-container>
      </div>

      <div *ngIf="filterConfigured('trafficGeneration')" fxLayout="row" fxLayoutAlign="start center">
        <ng-container *ngTemplateOutlet="trafficGenerationTemplate"></ng-container>
      </div>

      <div *ngIf="filterConfigured('owners')" fxLayout="row" fxLayoutAlign="start center">
        <ng-container *ngTemplateOutlet="ownerTemplate"></ng-container>
      </div>

      <ng-container *ngTemplateOutlet="nettoFromTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="nettoToTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="sexTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="isLongTermTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="orderTypeTemplate"></ng-container>

      <div *ngIf="filterConfigured('scheduleTypes')" fxLayout="row" fxLayoutAlign="start center">
        <ng-container *ngTemplateOutlet="scheduleTypesTemplate"></ng-container>
      </div>

      <ng-container *ngTemplateOutlet="keyAccountManagerTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="countryTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="canEmployUaTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="phoneTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="projectManagerTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="substituteManagerTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="recruiterTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="substituteRecruiterTemplate"></ng-container>

      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5%" *ngIf="companyCtrl && filterConfigured('company')">
        <ng-container *ngTemplateOutlet="companyTemplate"></ng-container>
      </div>

      <div *ngIf="filterConfigured('canUseInOrder')" fxLayout="row" fxLayoutAlign="start center">
        <ng-container *ngTemplateOutlet="canUseInOrderTemplate"></ng-container>
      </div>
    </ng-container>
  </div>
</form>

<mat-menu #menu="matMenu"
          yPosition="below"
          overlapTrigger="false"
          [ngClass]="style === 'mobile' ? 'filter-panel-mobile' : 'filter-panel-desktop'">
  <form [formGroup]="filterForm" (click)="$event.stopPropagation()" >
    <div class="filters-container-desktop" fxLayout="column" fxLayoutGap="15px">
      <div fxLayout="column">
        <span class="menu-heading">{{'SEARCH_BY_ALL_PARAMETERS' | translate}}</span>
      </div>
      <div fxLayout="column" fxLayoutGap="12px"  (click)="$event.stopPropagation()">

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5%" *ngIf="companyCtrl && filterConfigured('company')">
          <ng-container *ngTemplateOutlet="companyTemplate"></ng-container>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5%" *ngIf="filterConfigured('owners')">
          <ng-container *ngTemplateOutlet="ownerTemplate"></ng-container>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5%" *ngIf="filterConfigured('category')">
          <ng-container *ngTemplateOutlet="categoryTemplate"></ng-container>
        </div>

        <div class="task-status" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5%" *ngIf="taskStatusCtrl && filterConfigured('taskStatuses')">
          <ng-container *ngTemplateOutlet="taskStatusTemplate"></ng-container>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5%" *ngIf="regionCtrl && filterConfigured('region')">
          <ng-container *ngTemplateOutlet="regionTemplate"></ng-container>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5%" *ngIf="filterConfigured('statuses')">
          <ng-container *ngTemplateOutlet="statusesTemplate"></ng-container>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5%" *ngIf="filterConfigured('selectionStatuses')">
          <ng-container *ngTemplateOutlet="selectionStatusesTemplate"></ng-container>
        </div>

        <div fxLayout="row" fxLayoutAlign="center center"  fxLayoutGap="5%"  *ngIf="filterConfigured('dateRange')" >
          <ng-container *ngTemplateOutlet="dateRangeTemplate"></ng-container>
        </div>

        <div *ngIf="filterConfigured('intensity')" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5%">
          <ng-container *ngTemplateOutlet="intensityTemplate"></ng-container>
        </div>

        <div *ngIf="filterConfigured('duration')" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5%">
          <ng-container *ngTemplateOutlet="durationTemplate"></ng-container>
        </div>

        <div *ngIf="filterConfigured('transport')" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5%">
          <ng-container *ngTemplateOutlet="transportTemplate"></ng-container>
        </div>

        <div *ngIf="filterConfigured('perspectives')" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5%">
          <ng-container *ngTemplateOutlet="perspectivesTemplate"></ng-container>
        </div>

        <div *ngIf="filterConfigured('trafficGeneration')" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5%">
          <ng-container *ngTemplateOutlet="trafficGenerationTemplate"></ng-container>
        </div>

        <div *ngIf="filterConfigured('nettoFrom')" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5%">
          <ng-container *ngTemplateOutlet="nettoFromTemplate"></ng-container>
        </div>

        <div *ngIf="filterConfigured('nettoTo')" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5%">
          <ng-container *ngTemplateOutlet="nettoToTemplate"></ng-container>
        </div>

        <div *ngIf="filterConfigured('sex')" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5%">
          <ng-container *ngTemplateOutlet="sexTemplate"></ng-container>
        </div>

        <div *ngIf="filterConfigured('isLongTerm')" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5%">
          <ng-container *ngTemplateOutlet="isLongTermTemplate"></ng-container>
        </div>

        <div *ngIf="filterConfigured('orderType')" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5%">
          <ng-container *ngTemplateOutlet="orderTypeTemplate"></ng-container>
        </div>

        <div *ngIf="filterConfigured('scheduleTypes')" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5%">
          <ng-container *ngTemplateOutlet="scheduleTypesTemplate"></ng-container>
        </div>

        <div *ngIf="filterConfigured('keyAccountManager')" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5%">
          <ng-container *ngTemplateOutlet="keyAccountManagerTemplate"></ng-container>
        </div>

        <div *ngIf="filterConfigured('country')" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5%">
          <ng-container *ngTemplateOutlet="countryTemplate"></ng-container>
        </div>

        <div *ngIf="filterConfigured('firstTimeCall')" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5%">
          <ng-container *ngTemplateOutlet="firstTimeCallTemplate"></ng-container>
        </div>

        <div *ngIf="filterConfigured('canEmployUa')" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5%">
          <ng-container *ngTemplateOutlet="canEmployUaTemplate"></ng-container>
        </div>

        <div *ngIf="filterConfigured('phone')" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5%">
          <ng-container *ngTemplateOutlet="phoneTemplate"></ng-container>
        </div>

        <div *ngIf="filterConfigured('projectManager')" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5%">
          <ng-container *ngTemplateOutlet="projectManagerTemplate"></ng-container>
        </div>

        <div *ngIf="filterConfigured('substituteManager')" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5%">
          <ng-container *ngTemplateOutlet="substituteManagerTemplate"></ng-container>
        </div>

        <div *ngIf="filterConfigured('recruiter')" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5%">
          <ng-container *ngTemplateOutlet="recruiterTemplate"></ng-container>
        </div>

        <div *ngIf="filterConfigured('substituteRecruiter')" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5%">
          <ng-container *ngTemplateOutlet="substituteRecruiterTemplate"></ng-container>
        </div>
      </div>
    </div>
  </form>
</mat-menu>

<form [formGroup]="filterForm" [ngClass]="style">
  <ng-template #companyTemplate>
    <span fxFlex="20" class="filter-label" *ngIf="menuView">{{'COMPANY' | translate }}</span>
    <div [fxFlex]="menuView? 80: 'none'" fxLayout="row">
      <app-company-selector
        fxFlex="100"
        appearance="legacy"
        clearable="true"
        hidePrefix="true"
        [hideLabel]="menuView"
        [selectedCompany]="selectedCompany"
        (companyChange)="selectCompany($event)"
        (clearInput)="clearFilter('company')">
      </app-company-selector>
    </div>
  </ng-template>

  <ng-template #ownerTemplate>
    <span fxFlex="20" class="filter-label" *ngIf="menuView">{{'OWNER' | translate }}</span>
    <mat-form-field [fxFlex]="menuView? 80: 'none'">
      <mat-select matNativeControl formControlName="owners" multiple="true">
        <mat-option>
          <ngx-mat-select-search [formControl]="ownerFilterCtrl" placeholderLabel="{{'OWNER' | translate}}" ></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let userprofile of selectedOwners" [value]="userprofile.id">
            {{userprofile?.user?.firstName}} {{userprofile?.user?.lastName}}
        </mat-option>
        <div *ngIf="useInactiveUsersFiler" class="inactive-users-toggle">
          <mat-slide-toggle (change)="toggleInactiveUsers($event)">{{ 'SHOW_INACTIVE' | translate }}</mat-slide-toggle>
        </div>
      </mat-select>

      <button mat-icon-button matSuffix>
        <mat-icon inline="true" (click)="$event.stopPropagation(); clearFilter('owners')" matSuffix>close</mat-icon>
      </button>
    </mat-form-field>
  </ng-template>

  <ng-template #categoryTemplate>
    <span class="filter-label" *ngIf="menuView">{{'CATEGORY' | translate }}</span>
    <app-filter-category-select
      [fxFlex]="menuView ? 80 : 'none'"
      [filterBarView]="true"
      [categoryCtrl]="categoryCtrl"
      (clearFilter)="clearFilter('category')"
    ></app-filter-category-select>
  </ng-template>

  <ng-template #taskStatusTemplate>
    <span fxFlex="20" class="filter-label" *ngIf="menuView">{{'STATUS' | translate }}</span>
    <mat-form-field [fxFlex]="menuView? 80: 'none'">
      <mat-select formControlName="taskStatuses" multiple="true">
        <mat-option *ngFor="let status of taskStatuses" [value]="status">
          {{('TASK_STATUSES.' + status) | translate}}
        </mat-option>
      </mat-select>

      <button mat-icon-button matSuffix>
        <mat-icon inline="true" (click)="$event.stopPropagation(); clearFilter('taskStatuses')" matSuffix>close</mat-icon>
      </button>
    </mat-form-field>
  </ng-template>

  <ng-template #dateRangeTemplate>
    <span fxFlex="20" class="filter-label" *ngIf="menuView">{{'DATE_RANGE' | translate }}</span>
    <mat-form-field
      class="date-from-input"
      [class.small-new-date-range]='smallNewDateRange'
      [fxFlex]="menuView ? 80: 'none'"
      (click)='smallNewDateRange ? dateRangePicker.open() : null'
    >
      <mat-label *ngIf="!menuView && !smallNewDateRange">{{ 'DATE_RANGE' | translate }}</mat-label>
      <mat-date-range-input [formGroup]="dateRange" [rangePicker]="dateRangePicker">
        <input matStartDate formControlName="dateFrom">
        <input matEndDate formControlName="dateTo">
      </mat-date-range-input>
      <mat-datepicker-toggle *ngIf="!smallNewDateRange" matSuffix [for]="dateRangePicker"></mat-datepicker-toggle>
      <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
    </mat-form-field>
  </ng-template>

  <ng-template #regionTemplate>
    <span fxFlex="20" class="filter-label" *ngIf="menuView">{{'REGION' | translate }}</span>
    <mat-form-field [fxFlex]="menuView? 80: 'none'" *ngIf="regionCtrl && filterConfigured('region')" class="fixed-width">
      <mat-label *ngIf="!menuView">{{'REGION'  | translate }}</mat-label>
      <mat-select matNativeControl formControlName="region" name="region" multiple="true">

        <!-- Iterate over unique region groups -->
        <mat-optgroup *ngFor="let group of regionGroups " [label]="group.title" (click)="toggleAllRegionsInGroup(group)">

<!--          &lt;!&ndash; Create a checkbox for the group to select all &ndash;&gt;-->
<!--          <mat-checkbox-->
<!--            (change)="toggleAllRegionsInGroup(group, $event.checked)">-->
<!--            {{group.title}}-->
<!--          </mat-checkbox>-->

          <!-- List out regions for that group -->
          <mat-option *ngFor="let region of group.regions" [value]="region.id" (click)="$event.stopImmediatePropagation();">
            {{region.title}}
          </mat-option>

        </mat-optgroup>

      </mat-select>
      <button mat-icon-button matSuffix>
        <mat-icon inline="true" (click)="$event.stopPropagation(); clearFilter('region')" matSuffix>close</mat-icon>
      </button>

    </mat-form-field>
  </ng-template>

  <ng-template #regionTemplateOld>
    <span fxFlex="20" class="filter-label" *ngIf="menuView">{{'REGION' | translate }}</span>
    <mat-form-field [fxFlex]="menuView? 80: 'none'" *ngIf="regionCtrl && filterConfigured('region')" class="fixed-width">
      <mat-label *ngIf="!menuView">{{'REGION'  | translate }}</mat-label>
      <mat-select matNativeControl formControlName="region" name="region" multiple="true">

        <mat-option *ngFor="let region of regions " [value]="region.id">
            {{region.title}}
        </mat-option>

      </mat-select>
      <button mat-icon-button matSuffix>
        <mat-icon inline="true" (click)="$event.stopPropagation(); clearFilter('region')" matSuffix>close</mat-icon>
      </button>

    </mat-form-field>
  </ng-template>

  <ng-template #statusesTemplate>
    <span fxFlex="20" class="filter-label" *ngIf="menuView">{{'STATUSES' | translate }}</span>
    <mat-form-field [fxFlex]="menuView? 80: 'none'" *ngIf="filterConfigured('statuses')" >
      <mat-label *ngIf="!menuView">{{'STATUSES' | translate }}</mat-label>
      <mat-select matNativeControl formControlName="statuses" name="status" multiple>

        <mat-option *ngFor="let status of availableStatuses"
                    [value]="orderStatuses[status]"
                    [matTooltip]="('STATUS_EXPLANATION_' + status | uppercase) | translate"
        >
            {{status}}
        </mat-option>

      </mat-select>
      <button mat-icon-button matSuffix>
        <mat-icon inline="true" (click)="$event.stopPropagation(); clearFilter('statuses')" matSuffix>close</mat-icon>
      </button>
    </mat-form-field>
  </ng-template>

  <ng-template #selectionStatusesTemplate>
    <span fxFlex="20" class="filter-label" *ngIf="menuView">{{'STATUSES' | translate }}</span>
    <mat-form-field [fxFlex]="menuView? 80: 'none'" *ngIf="filterConfigured('selectionStatuses')" >
      <mat-label *ngIf="!menuView">{{'STATUSES_SELECTION' | translate }}</mat-label>
      <mat-select matNativeControl formControlName="selectionStatuses" name="status" multiple>

        <mat-option *ngFor="let status of availableSelectionStatuses"
                    [value]="selectionStatuses[status]"
                    [matTooltip]="('STATUS_EXPLANATION_' + status | uppercase) | translate"
        >
          {{(status | uppercase) | translate}}
        </mat-option>

      </mat-select>
      <button mat-icon-button matSuffix>
        <mat-icon inline="true" (click)="$event.stopPropagation(); clearFilter('selectionStatuses')" matSuffix>close</mat-icon>
      </button>
    </mat-form-field>
  </ng-template>

  <ng-template #intensityTemplate>
    <span fxFlex="20" class="filter-label" *ngIf="menuView">{{'Darbas' | translate }}</span>
    <app-dropdown-multiple-filter [fxFlex]="menuView? 80 : 'none'" [data]="intensity" [placeholder]="'Darbas'" (change)="this.filterForm.get('intensity').setValue($event)"></app-dropdown-multiple-filter>
  </ng-template>

  <ng-template #durationTemplate>
    <span fxFlex="20" class="filter-label" *ngIf="menuView">{{'Trukmė' | translate }}</span>
    <app-dropdown-multiple-filter [fxFlex]="menuView? 80 : 'none'" [data]="duration" [placeholder]="'Trukmė'" (change)="this.filterForm.get('duration').setValue($event)"></app-dropdown-multiple-filter>
  </ng-template>

  <ng-template #transportTemplate>
    <span fxFlex="20" class="filter-label" *ngIf="menuView">{{'Transportas' | translate }}</span>
    <app-dropdown-multiple-filter [fxFlex]="menuView? 80 : 'none'" [data]="transport" [placeholder]="'Transportas'" (change)="this.filterForm.get('transport').setValue($event)"></app-dropdown-multiple-filter>
  </ng-template>

  <ng-template #perspectivesTemplate>
    <span fxFlex="20" class="filter-label" *ngIf="menuView">{{'Perspektyvos' | translate }}</span>
    <app-dropdown-multiple-filter [fxFlex]="menuView? 80 : 'none'" [data]="perspectives" [placeholder]="'Perspektyvos'" (change)="this.filterForm.get('perspectives').setValue($event)"></app-dropdown-multiple-filter>
  </ng-template>

  <ng-template #trafficGenerationTemplate>
    <span fxFlex="20" class="filter-label" *ngIf="menuView">{{'Srautas' | translate }}</span>
    <app-dropdown-multiple-filter [fxFlex]="menuView? 80 : 'none'" [data]="trafficGenOpts" [placeholder]="'Srautas'"
                                  (change)="this.filterForm.get('trafficGeneration').setValue($event)">
    </app-dropdown-multiple-filter>
  </ng-template>

  <ng-template #nettoFromTemplate>
    <span fxFlex="20" class="filter-label" *ngIf="menuView">{{'ORDER.NETTO_FROM' | translate }}</span>
    <mat-form-field  [fxFlex]="menuView? 80 : 'none'" *ngIf="filterConfigured('nettoFrom')" [ngClass]="{'fixed-width': !menuView}">
      <mat-label *ngIf="!menuView">{{'ORDER.NETTO_FROM' | translate }}</mat-label>
      <input matInput type="number" formControlName="nettoFrom">
    </mat-form-field>
  </ng-template>

  <ng-template #nettoToTemplate>
    <span fxFlex='20' class='filter-label' *ngIf='menuView'>{{'ORDER.NETTO_TO' | translate }}</span>
    <mat-form-field [fxFlex]="menuView? 80 : 'none'" *ngIf="filterConfigured('nettoTo')"
                    [ngClass]="{'fixed-width': !menuView}">
      <mat-label *ngIf='!menuView'>{{'ORDER.NETTO_TO' | translate }}</mat-label>
      <input matInput type='number' formControlName='nettoTo'>
    </mat-form-field>
  </ng-template>

  <ng-template #sexTemplate>
    <span fxFlex="20" class="filter-label" *ngIf="menuView">{{'SEX' | translate }}</span>
    <mat-form-field [fxFlex]="menuView? 80 : 'none'" *ngIf="filterConfigured('sex')" [ngClass]="{'fixed-width': !menuView}">
      <mat-label *ngIf="!menuView"> {{'SEX' | translate}} </mat-label>
      <select matNativeControl formControlName="sex" name="sex">
        <option value=""> {{'IRRELEVANT' | translate}} </option>
        <option value="male"> {{'FOR_MAN' | translate }} </option>
        <option value="female"> {{'FOR_WOMAN' | translate }} </option>
      </select>
    </mat-form-field>
  </ng-template>

  <ng-template #isLongTermTemplate>
    <span fxFlex="20" class="filter-label" *ngIf="menuView">{{'DURATION' | translate }}</span>
    <mat-form-field [fxFlex]="menuView? 80 : 'none'" *ngIf="filterConfigured('isLongTerm')" [ngClass]="{'fixed-width': !menuView}">
      <mat-label *ngIf="!menuView"> {{'DURATION' | translate}} </mat-label>
      <select matNativeControl formControlName="isLongTerm" name="isLongTerm">
        <option [ngValue]="null"> {{'IRRELEVANT' | translate}} </option>
        <option [ngValue]="true"> {{'LONG_TERM' | translate}} </option>
        <option [ngValue]="false"> {{'SHORT_TERM' | translate}} </option>
      </select>
    </mat-form-field>
  </ng-template>

  <ng-template #orderTypeTemplate>
    <span fxFlex="20" class="filter-label" *ngIf="menuView">{{'ORDER.ORDER_TYPE' | translate }}</span>
    <mat-form-field [fxFlex]="menuView? 80 : 'none'" *ngIf="filterConfigured('orderType')" [ngClass]="{'fixed-width': !menuView}">
      <mat-label *ngIf="!menuView"> {{'ORDER.ORDER_TYPE' | translate}} </mat-label>
      <select matNativeControl formControlName="orderType" name="orderType">
        <option value=""> {{'IRRELEVANT' | translate}} </option>
        <option value="STAFFING"> {{'ORDER.ORDER_TYPE_OPTIONS.STAFFING' | translate}} </option>
        <option value="SELECTION"> {{'ORDER.ORDER_TYPE_OPTIONS.SELECTION' | translate}} </option>
        <option value="PARTIAL_SELECTIONS"> {{'ORDER.ORDER_TYPE_OPTIONS.PARTIAL_SELECTION' | translate}} </option>
      </select>
    </mat-form-field>
  </ng-template>

  <ng-template #scheduleTypesTemplate>
    <span fxFlex="20" class="filter-label" *ngIf="menuView">{{'SCHEDULE.SCHEDULE' | translate }}</span>
    <app-dropdown-multiple-filter
      [fxFlex]="menuView? 80 : 'none'"
      [data]="scheduleTypes"
      [placeholder]="'SCHEDULE.SCHEDULE' | translate"
      [preselected]="preselectScheduleTypes"
      (change)="this.filterForm.get('scheduleTypes').setValue($event.join(','))">
    </app-dropdown-multiple-filter>
  </ng-template>

  <ng-template #keyAccountManagerTemplate>
    <span fxFlex='20' class='filter-label' *ngIf='menuView'>{{'FILTERS.KEY_ACCOUNT_MANAGER' | translate }}</span>
    <mat-form-field [fxFlex]="menuView? 80 : 'none'" *ngIf="filterConfigured('keyAccountManager')">
      <mat-label *ngIf="!menuView">{{'FILTERS.KEY_ACCOUNT_MANAGER' | translate}}</mat-label>
      <mat-select matNativeControl formControlName="keyAccountManager" name="contact">
        <mat-option>
          <ngx-mat-select-search [formControl]="keyAccountManagerCtrl" placeholderLabel="{{'COMMON.SEARCH' | translate}}" ></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor='let userprofile of accountManagers' [value]='userprofile.id'>
          {{userprofile?.user?.firstName}} {{userprofile?.user?.lastName}}
        </mat-option>
      </mat-select>

      <button mat-icon-button matSuffix>
        <mat-icon inline="true" (click)="$event.stopPropagation(); clearFilter('keyAccountManager')" matSuffix>close</mat-icon>
      </button>
    </mat-form-field>
  </ng-template>

  <ng-template #projectManagerTemplate>
    <mat-form-field [fxFlex]="menuView? 80 : 'none'" *ngIf="filterConfigured('projectManager')">
      <mat-label>{{ 'CLIENT.PROJECT_MANAGER' | translate }}</mat-label>
      <mat-select matNativeControl formControlName="projectManager" name="contact">
        <mat-option>
          <ngx-mat-select-search [formControl]="searchProjectManagerCtrl" placeholderLabel="{{ 'COMMON.SEARCH' | translate }}" ></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor='let userprofile of projectManagers' [value]='userprofile.id'>
          {{ userprofile?.user?.firstName }} {{ userprofile?.user?.lastName }}
        </mat-option>
      </mat-select>

      <button mat-icon-button matSuffix>
        <mat-icon inline="true" (click)="$event.stopPropagation(); clearFilter('projectManager')" matSuffix>close</mat-icon>
      </button>
    </mat-form-field>
  </ng-template>

  <ng-template #substituteManagerTemplate>
    <mat-form-field [fxFlex]="menuView? 80 : 'none'" *ngIf="filterConfigured('substituteManager')">
      <mat-label>{{ 'CLIENT.SUBSTITUTE_MANAGER' | translate }}</mat-label>
      <mat-select matNativeControl formControlName="substituteManager" name="contact">
        <mat-option>
          <ngx-mat-select-search [formControl]="searchSubstituteManagersCtrl" placeholderLabel="{{'COMMON.SEARCH' | translate}}" ></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor='let userprofile of substituteManagers' [value]='userprofile.id'>
          {{ userprofile?.user?.firstName }} {{ userprofile?.user?.lastName }}
        </mat-option>
      </mat-select>

      <button mat-icon-button matSuffix>
        <mat-icon inline="true" (click)="$event.stopPropagation(); clearFilter('substituteManager')" matSuffix>close</mat-icon>
      </button>
    </mat-form-field>
  </ng-template>

  <ng-template #recruiterTemplate>
    <mat-form-field [fxFlex]="menuView? 80 : 'none'" *ngIf="filterConfigured('recruiter')">
      <mat-label>{{ 'CLIENT.RECRUITER' | translate }}</mat-label>
      <mat-select matNativeControl formControlName="recruiter" name="contact">
        <mat-option>
          <ngx-mat-select-search [formControl]="searchRecruiterCtrl" placeholderLabel="{{'COMMON.SEARCH' | translate}}" ></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor='let userprofile of recruiters' [value]='userprofile.id'>
          {{ userprofile?.user?.firstName }} {{ userprofile?.user?.lastName }}
        </mat-option>
      </mat-select>

      <button mat-icon-button matSuffix>
        <mat-icon inline="true" (click)="$event.stopPropagation(); clearFilter('recruiter')" matSuffix>close</mat-icon>
      </button>
    </mat-form-field>
  </ng-template>

  <ng-template #substituteRecruiterTemplate>
    <mat-form-field [fxFlex]="menuView? 80 : 'none'" *ngIf="filterConfigured('substituteRecruiter')">
      <mat-label>{{ 'CLIENT.SUBSTITUTE_RECRUITER' | translate }}</mat-label>
      <mat-select matNativeControl formControlName="substituteRecruiter" name="contact">
        <mat-option>
          <ngx-mat-select-search [formControl]="searchSubstituteRecruiterCtrl" placeholderLabel="{{'COMMON.SEARCH' | translate}}" ></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor='let userprofile of substituteRecruiters' [value]='userprofile.id'>
          {{ userprofile?.user?.firstName }} {{ userprofile?.user?.lastName }}
        </mat-option>
      </mat-select>

      <button mat-icon-button matSuffix>
        <mat-icon inline="true" (click)="$event.stopPropagation(); clearFilter('substituteRecruiter')" matSuffix>close</mat-icon>
      </button>
    </mat-form-field>
  </ng-template>

  <ng-template #countryTemplate>
    <span fxFlex="20" class="filter-label" *ngIf="menuView">{{'COUNTRY' | translate }}</span>
    <mat-form-field [fxFlex]="menuView? 80 : 'none'" *ngIf="filterConfigured('country')">
      <mat-label *ngIf="!menuView">{{'COUNTRY' | translate}}</mat-label>
      <mat-select matNativeControl formControlName="country" name="country">
        <mat-option *ngFor="let country of countries" [value]="country.id">{{country.isoCode}}</mat-option>
      </mat-select>

      <button mat-icon-button matSuffix>
        <mat-icon inline="true" (click)="$event.stopPropagation(); clearFilter('country')" matSuffix>close</mat-icon>
      </button>
    </mat-form-field>
  </ng-template>

  <ng-template #firstTimeCallTemplate>
    <span fxFlex="20" class="filter-label" *ngIf="menuView">{{'FIRST_TIME_CALL' | translate }}</span>
    <div *ngIf="filterConfigured('firstTimeCall')"  class='input-checkbox'>
      <mat-checkbox formControlName="firstTimeCall"></mat-checkbox>
    </div>
  </ng-template>


  <ng-template #canEmployUaTemplate>
    <span fxFlex="20" class="filter-label" *ngIf="menuView">{{'CLIENT.COMPANY_ADDITIONAL_INFO_OPTIONS.CAN_EMPLOY_UKRAINIANS' | translate }}</span>
    <mat-form-field [fxFlex]="menuView? 80 : 'none'" *ngIf="filterConfigured('canEmployUa')" class="fixed-width">
      <mat-label *ngIf="!menuView"> {{'CLIENT.COMPANY_ADDITIONAL_INFO_OPTIONS.CAN_EMPLOY_UKRAINIANS' | translate}} </mat-label>
      <select matNativeControl formControlName="canEmployUa" name="canEmployUa">
        <option [ngValue]="null"> {{'IRRELEVANT' | translate}} </option>
        <option [ngValue]="true"> {{'YES' | translate }} </option>
        <option [ngValue]="false"> {{'NO' | translate }} </option>
      </select>
    </mat-form-field>
  </ng-template>

  <ng-template #phoneTemplate>
    <span fxFlex="20" class="filter-label" *ngIf="menuView">{{'PHONE' | translate }}</span>
    <mat-form-field [fxFlex]="menuView? 80 : 'none'" *ngIf="filterConfigured('phone')">
      <mat-label *ngIf="!menuView">{{'PHONE' | translate}}</mat-label>

      <input matInput formControlName="phone" placeholder="{{'PHONE' | translate }}">

      <button mat-icon-button matSuffix *ngIf="phoneInput?.dirty">
        <mat-icon inline="true" (click)="clearFilter('phone')" matSuffix>close</mat-icon>
      </button>
    </mat-form-field>
  </ng-template>

  <ng-template #canUseInOrderTemplate>
    <div *ngIf="canUseInOrderCtrl.value" class="can-use-in-order-container" (click)="canUseInOrderCtrl.setValue(false)">
      <mat-icon>visibility</mat-icon>
      <span>{{ 'Necessary visible' }}</span>
    </div>

    <div *ngIf="!canUseInOrderCtrl.value" class="can-use-in-order-container" (click)="canUseInOrderCtrl.setValue(true)">
      <mat-icon>visibility_off</mat-icon>
      <span>{{ 'Unnecessary visible' }}</span>
    </div>
  </ng-template>
</form>

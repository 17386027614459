import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  Contact,
  DeleteOrderPeriodGQL,
  OrderPeriod,
  Specification,
  SpecificationsGQL,
  UpdateCompanyDescriptionGQL,
  UpdateOrderGQL,
  UserProfile
} from '../../graphql/graphql';
import { FormBuilder, FormControl, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { WorkwearIssuedBy } from '../workwear-issued-by';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '../../shared/notification.service';
import {
  ExperienceTypes,
  SpecificationEditComponent
} from '../../specification/specification-edit/specification-edit.component';
import { TagGroup } from '../../specification/tag-assignment-table/tag';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { UserProfileGQL } from 'src/app/auth/graphql';
import { OrdersService } from '../orders.service';
import {
  ProjectManagerSelectorComponent
} from 'src/app/shared/project-manager-selector/project-manager-selector.component';
import { OrderTrafficGeneration } from '../../shared/types/order-traffic-generation';
import { OrderTypeEnum, TagGroupKey } from '../../shared/types/order-types';
import { RelayIdService } from 'src/app/shared/relay-id.service';
import {
  BaseOrderFieldsFragment,
  ClientInterviewCreateGQL,
  ClientInterviewUpdateGQL,
  CompanyMetaFieldsFragment,
  CompanyTypeFieldsFragment,
  CopySpecificationGQL,
  CountryFieldsFragment,
  CreateOrderGQL,
  GetCountriesGQL,
  OrderEditMetadataGQL, OrderErrorType,
  OrderInput,
  OrderLanguageEnum,
  OrderOrderType,
  OrderPeriodType,
  OrderStatus,
  OrderSubtype,
  OrderType,
  QualificationEnum,
  RegionsList2FieldsFragment,
  SpecificationCreateGQL,
  SpecificationDetailFieldsFragment,
  SpecificationModelCreateMutationInput,
  SpecificationModelUpdateMutationInput,
  SpecificationUpdateGQL,
  UserProfileFieldsFragment
} from '../../../generated/graphql';
import { Observable, Subscription } from 'rxjs';
import { hasBruttoValue, hasBruttoValueForUseInDoc, hasId } from './validators';
import { OrderCreateService } from '../../order/order-create/order-create.service';
import { GroupByPipe } from 'ngx-pipes';
import { TranslateService } from '@ngx-translate/core';
import { CanDeactivateGuard } from 'src/app/shared/guards/can-deactivate.guard';
import { OrderActionsLogService } from '../order-actions-log/order-actions-log.service';
import { ClientNeedComponent } from './client-need/client-need.component';
import { Title } from '@angular/platform-browser';
import { Constants } from 'src/app/constants';
import { OrderDataErrorReportsComponent } from '../order-data-error-reports/order-data-error-reports.component';

const defaultCompansationForRecruitement = '\tSutarties 8.1 p. atveju mokamas mokestis: šiame užsakyme nurodytas darbuotojui mokamas valandinis darbo užmokestis, padaugintas iš 130, už kiekvieną darbuotoją.\n' +
  '\tSutarties 8.2 p. atveju mokamas padidintas mokestis: šiame užsakyme nurodytas darbuotojui mokamas valandinis darbo užmokestis, padaugintas iš 390, už kiekvieną darbuotoją.';


const DEFAULT_CANCELLATION_PERIOD = 30;

enum Subs {
  order = 'ORDER_SUBSCRIPTION',
  companyChange = 'COMPANY_CHANGE'
}
@Component({
  selector: 'app-order-create',
  templateUrl: './order-create.component.html',
  styleUrls: ['./order-create.component.scss']
})
export class OrderCreateComponent implements OnInit, OnDestroy, CanDeactivateGuard {
  selectedSchedule = new UntypedFormControl({value: [7]});

  subscriptions: Subscription[] = [];
  showInfoForCopy: boolean;
  periods: OrderPeriod[] = [];

  trafficGenerationOptions = OrderTrafficGeneration;
  orderTypes = OrderTypeEnum;

  workwearIssuedBy = WorkwearIssuedBy;

  tagsGroups: TagGroup[];
  orderForm: FormGroup;
  selectedCompany: CompanyMetaFieldsFragment;
  selectedContact: Contact;

  OrderSubtype = OrderSubtype;

  showPeriodErrors = false;

  projectManagers: UserProfileFieldsFragment[] = [];

  specifications: Specification[];
  createdSpecification: SpecificationDetailFieldsFragment;
  selectedSpecification: Specification;
  regions: RegionsList2FieldsFragment[];

  currentProjectManager: UserProfile;

  newSpecification = false;

  selectedSpecDisabled = false;

  private editOrderId$: string;

  selectionInterviewGuide = new FormGroup({
    first: new FormControl(),
  });

  orderErrors: OrderErrorType[] = [];

  @ViewChild(SpecificationEditComponent) specificationView;
  @ViewChild(ProjectManagerSelectorComponent) projectManagerSelector;
  @ViewChild(ClientNeedComponent) clientNeeded: ClientNeedComponent;
  today = new Date();
  orderId: any;
  statsDetail = false;
  private metaSubscription: Subscription;
  // get clientContactCtrl() {return this.orderForm && this.orderForm.get('clientContact'); }
  private tagGroupMap = TagGroupKey;

  get companyCtrl() { return this.clientAndContactCtrl && this.clientAndContactCtrl.get('company'); }
  get orderTypeCtrl() { return this.orderForm && this.orderForm.get('orderType'); }
  get clientAndContactCtrl() {return this.orderForm && this.orderForm.get('clientAndContactForm'); }
  get clientNeedCtrl() {return this.orderForm && this.orderForm.get('clientNeedForm'); }
  get specificationCtrl() {return this.orderForm && this.orderForm.get('specificationForm'); }
  get recruitmentProgressReportFrequencyCtrl() {
    return this.orderForm && this.orderForm.get('recruitmentProgressReportFrequency') as FormControl;
  }
  get reportingFrequencyStartDateCtrl() {
    return this.orderForm && this.orderForm.get('reportingFrequencyStartDate') as FormControl;
  }
  get descriptionCtrl() { return this.clientAndContactCtrl && this.clientAndContactCtrl.get('description'); }
  get salesQuestionnaireCtrl() { return this.clientAndContactCtrl && this.clientAndContactCtrl.get('salesQuestionnaire'); }
  get formPeriods() { return this.clientNeedCtrl && this.clientNeedCtrl.get('periods'); }
  get formCompany() { return this.clientAndContactCtrl && this.clientAndContactCtrl.get('company'); }

  @Input()
  set editOrderId(value: string) {
    if (value) {
      this.editOrderId$ = value;
    }
  }

  get editOrderId() {
    return this.editOrderId$;
  }

  get relayOrderId() {
    return btoa('OrderMType:' + this.editOrderId$);
  }

  get isSelection() { return this.orderTypeCtrl?.value === OrderTypeEnum.selection; }
  get isPartialSelection() { return this.orderTypeCtrl?.value === OrderTypeEnum.partialSelection; }

  compensationForRecruitmentPrefill = '';
  minVsdfPaymentPrefill = '';
  workTimeRegistrationSystemPrefill = '';

  isLinear = true;
  loading = false;

  languages = OrderLanguageEnum;
  availableLanguages = [];
  countries: CountryFieldsFragment[] = [];
  selectedCountry: CountryFieldsFragment;
  selectedCountryId = localStorage.getItem(Constants.createOrderCountryKey);
  selectedOrder: BaseOrderFieldsFragment;

  orderCreated = false;
  createdFromBlank = false;

  invalidControls = {
    step1: '',
    step2: '',
    step3: ''
  };
  /**
   * Guard against browser refresh, close, etc.
   * Checks if user has some unsaved changes
   * before leaving the page.
   */
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    if (this.orderForm.dirty && !this.orderCreated) {
      return false;
    }
    return true;
  }

  /**
   * Is used as a workaround to call getTranslations function
   * which, if called from injected service, changes the translations
   * of the page
   */
  translate2: TranslateService;

  constructor(private metaGQL: OrderEditMetadataGQL,
              private createOrderGQL: CreateOrderGQL,
              private specificationsGQL: SpecificationsGQL,
              private fb: FormBuilder,
              private dialog: MatDialog,
              private notify: NotificationService,
              private updateOrderGQL: UpdateOrderGQL,
              private deletePeriodGQL: DeleteOrderPeriodGQL,
              private router: Router,
              private userprofileGQL: UserProfileGQL,
              private ordersSevice: OrdersService,
              private orderCreateService: OrderCreateService,
              private copySpecGQL: CopySpecificationGQL,
              private relayIdService: RelayIdService,
              private route: ActivatedRoute,
              private elementRef: ElementRef,
              private groupBy: GroupByPipe,
              private createSpecGQL: SpecificationCreateGQL,
              private updateCompanyDescriptionGQL: UpdateCompanyDescriptionGQL,
              private updateSpecGQL: SpecificationUpdateGQL,
              private translateService: TranslateService,
              private clientInterviewCreateGQL: ClientInterviewCreateGQL,
              private clientInterviewUpdateGQL: ClientInterviewUpdateGQL,
              public translate: TranslateService,
              public orderActionLogService: OrderActionsLogService,
              private countriesGQL: GetCountriesGQL,
              private cd: ChangeDetectorRef,
              private title: Title
  ) {
    this.translate2 = Object.create(this.translateService);
    this.loadUserData();
  }

  ngOnInit() {
    if (!this.editOrderId) {
      this.cleanForm();
      this.initOrderForm(undefined);
      this.orderCreateService.editOrderId.next(null);
    } else {
      this.initOrderForm(undefined);
      this.orderCreateService.editOrderId.next(this.editOrderId);
      this.getOrder(btoa(`OrderMType:${this.editOrderId}`));
      this.isLinear = false;
      this.setTrafficGenerationListener();
    }

    this.setCountryChangeListener();
    this.setLanguageChangeListener();

    this.subscriptions[Subs.companyChange] = this.orderCreateService.selectedCompany
      .subscribe(value => this.onCompanyChange(value));

    this.fetchCountries();
  }

  async fetchCountries() {
    const v = await this.countriesGQL.fetch().toPromise();
    this.countries = v.data.countries.edges.map(n => n.node);
    if (!this.editOrderId) {
      const country = this.countries.find(c => c.id === this.selectedCountryId) || this.countries[0];
      this.orderForm.get('country').setValue(country?.id);
      this.selectedCountry = country;
    } else {
      const value = this.orderForm.get('country').value;
      const country = this.countries.filter(c => c.id === value)[0];
      this.updateAvailableLanguages(country?.isoCode, this.orderForm.get('language').value);
    }
  }

  setCountryChangeListener() {
    this.subscriptions.push(
      this.orderForm.get('country').valueChanges.subscribe(async val => {
        if (!this.countries.length) {
          await this.fetchCountries();
        }

        if (!this.orderId) {
          localStorage.setItem(Constants.createOrderCountryKey, val);
        }
        const selectedCountry = this.countries.find(c => c.id === val);
        this.selectedCountry = selectedCountry;
        this.orderCreateService.setCountry(val);
        this.availableLanguages = [];
        this.updateAvailableLanguages(selectedCountry?.isoCode, this.orderForm.get('language')?.value);


        if (!this.editOrderId) {
          if (selectedCountry?.isoCode === 'lv') {
            if (!this.orderForm.get('orderNumber')) {
              this.orderForm.addControl('orderNumber', new FormControl());
            }
            if (!this.orderForm.get('subtype')) {
              this.orderForm.addControl('subtype', new FormControl());
            }
          } else if (selectedCountry?.isoCode === 'ee') {
            if (!this.orderForm.get('subtype')) {
              this.orderForm.addControl('subtype', new FormControl());
            }
          }
          else {
            this.orderForm.removeControl('orderNumber');
            this.orderForm.removeControl('subtype');
          }
        }
      })
    );
  }

  setLanguageChangeListener() {
    this.subscriptions.push(
      this.orderForm.get('language').valueChanges.subscribe(v => {
        this.orderCreateService.setLanguage(v);
      })
    );
  }

  getOrder(orderId: string, removeInheritedOrderPeriods = false) {
    this.subscriptions[Subs.order] =
      this.orderCreateService.getOrder(orderId).subscribe(res => {
        const selectedOrder = {...res.data?.orderModel};

        if (removeInheritedOrderPeriods) {
          selectedOrder.orderperiodSet = [];
        }

        this.orderErrors = selectedOrder.ordererrorSet.edges?.map(edge => edge.node as OrderErrorType) || [];

        this.selectedOrder = JSON.parse(JSON.stringify(selectedOrder));
        this.initOrderForm({...selectedOrder});
      },
      error => {
        console.error(error);
      });
  }

  cleanForm() {
    this.orderCreateService.editOrderId.next(null);
    this.orderCreateService.selectedCompany.next(null);
    this.selectedOrder = null;
    this.editOrderId$ = null;
    if (this.orderForm) {
      this.orderForm.reset();
      this.orderForm = null;
    }
    this.initOrderForm(null);
  }

  setTrafficGenerationListener() {
    this.subscriptions['trafficGenerationSub'] = this.orderForm.get('trafficGeneration').valueChanges.subscribe(val => {
        const order = { trafficGeneration: val };
        this.updateTrafficGeneration(order);
      });
  }

  private updateTrafficGeneration(order) {
    if (this.editOrderId) {
      this.updateOrder({ id: this.editOrderId, order });
    }
  }


  private updateOrder(order) {
    // if (order?.order?.orderType === OrderTypeEnum.selection) {
    //     this.saveClientInterview();
    // }


    this.updateOrderGQL.mutate(order, {refetchQueries: ['getOrderForPrefill']}).subscribe(
      r => {
        this.loading = false;
        this.selectedOrder = {
          ...this.selectedOrder,
          orderperiodSet: r.data.updateOrder.order.orderperiodSet as unknown as OrderPeriodType[]
        };
        this.notify.notify('ORDER_UPDATED');
      },
      err => {
        this.loading = false;
        this.notify.notify(`Could not update order. ERROR ${err}`);
      }
    );
  }

  private updateOrderWithPeriods(order, periods) {
    if (this.editOrderId) {
      this.updateOrder({ id: this.editOrderId, order, periods });
    }
  }

  private initOrderForm(order?) {
    const language = localStorage.getItem(Constants.createOrderLanguageKey) || OrderLanguageEnum.LanguageLt;
    if (!this.editOrderId) {
      // On new orders prefill appropriate value depending on selected order language;
      this.prefillCompensationInput(language);
    }

    if (!this.orderForm) {
      this.orderForm = this.fb.group({
        orderType: this.orderTypes.staffing,
        trafficGeneration: this.trafficGenerationOptions.inactive,
        language,
        country: new FormControl(localStorage.getItem(Constants.createOrderCountryKey)),
        clientAndContactForm: this.buildClientAndContractFormGroup(),
        clientNeedForm: this.buildClientNeedFormGroup(),
        specificationForm: this.buildSpecificationFormGroup(),
        selectedSchedule: new FormControl([9]),
        orderNumber: new FormControl(order?.orderNumber),
        subtype: new FormControl(order?.subtype),
        recruitmentProgressReportFrequency: new FormControl(order?.recruitmentProgressReportFrequency || null),
        reportingFrequencyStartDate: new FormControl(order?.reportingFrequencyStartDate || null)
      });
    }

    this.setSelectionFieldsDefaultValues();
    this.setFieldsDefaultValues();
    if (order) {
      this.orderForm.get('orderType').disable();
      this.initOrder(order);
      this.cd.detectChanges();
    } else {
      this.orderForm.get('orderType').enable();
      this.orderCreateService.selectedLanguage.next(this.orderForm.get('language').value);
      this.orderForm.get('orderType').valueChanges.subscribe(value => {
        if (value) {
          this.setSelectionFieldsDefaultValues();
        }
      });
    }
  }


  private loadUserData() {

  }

  isStatusNew(status: OrderStatus) {
    return [OrderStatus.Proposal, OrderStatus.New].indexOf(status) > -1;
  }

  showErrors() {

    this.showPeriodErrors = true;
    this.orderForm.markAsTouched();
    this.clientAndContactCtrl.markAllAsTouched();
    this.clientNeedCtrl.markAsTouched();
    this.specificationCtrl.markAllAsTouched();


  }

  updateCompanyDescription(message: string = 'Spec created, Order created successfully, Įmonės aprašymas išsaugotas') {

    this.updateCompanyDescriptionGQL.mutate({
        id: this.orderCreateService.selectedCompany.value.id,
        description: this.descriptionCtrl.value,
        salesQuestionnaire: this.salesQuestionnaireCtrl.value
    }).subscribe(res => {
          this.notify.notify(message);
    }, err => {
          this.notify.notify('Klaida bandant išsaugoti įmonės aprašymą', 2);
    });
  }

  private buildClientAndContractFormGroup() {
    const fg = new FormGroup({
      company: new FormControl(undefined, [hasId()]),
      clientContact: new FormControl(undefined, [Validators.required]),
      region: new FormControl(undefined, [Validators.required]),
      additionalRegions: new FormControl([], []),
      cancellationPeriod: new FormControl(DEFAULT_CANCELLATION_PERIOD),
      compensationForRecruitment: new FormControl(this.compensationForRecruitmentPrefill, ),
      description: new FormControl(''),
      additionalContacts: new FormControl([], [Validators.required]),
      selectedOrder: new FormControl(undefined, ),
      contract: new FormControl(undefined),
      projectManager: new FormControl(undefined, [Validators.required]),
      recruiter: new FormControl(undefined, []),
      biuroSignee: new FormControl(undefined, []),
      isLogoPublicAllowed: new FormControl(false),
      isCompanyInternational: new FormControl(false),
      isCompanyModern: new FormControl(false),
      isWorkplaceVisitAllowed: new FormControl(false),
      plannedTermForProvision: new FormControl(undefined),
      provisionOfInformationOnConclusion: new FormControl(undefined),
      remunerationAmount: new FormControl(undefined),
      warrantyPeriod: new FormControl(undefined),
      selectedSchedule: new UntypedFormControl({value: [7]}),
      minVsdfPayment: new FormControl(this.minVsdfPaymentPrefill),
      otherInfo: new FormControl(),
      workTimeRegistrationSystem: new FormControl(this.workTimeRegistrationSystemPrefill),
      displayPlannedTermForProvision: new FormControl(false),
      exportFreetextRequirements: new FormControl(false),
      salesQuestionnaire: new FormControl('')
    });

    this.subscriptions.push(
      fg.valueChanges.subscribe(() => {
        window.setTimeout(() => {
          this.invalidControls.step1 = Object.keys(fg.controls)
            .filter(control => control === 'company' ? !fg.controls[control].value?.id : fg.controls[control].status !== 'VALID')
            .map(control => control.split(/(?=[A-Z])/).join(' ').toLowerCase())
            .join(', ');
        }, 0);
      })
    );

    return fg;
  }

  private setFieldsDefaultValues() {
    this.clientAndContactCtrl.patchValue(
      {
        salesQuestionnaire: this.translateService.instant('ORDER.ORDER_CREATE_PREFILL.SALES_QUESTIONNAIRE')
      }
    );
  }

  private setSelectionFieldsDefaultValues() {
    // TODO fix this - take order language
    const newValue = {
      plannedTermForProvision: this.translateService.instant('ORDER.SELECTION_FIELDS_PREFILLS.PLANNED_TERM_FOR_PROVISION'),
      provisionOfInformationOnConclusion: this.translateService.instant('ORDER.SELECTION_FIELDS_PREFILLS.PREVISION_OF_INFORMATION_ON_CONCLUSION'),
      remunerationAmount: this.translateService.instant(this.isPartialSelection ? 'ORDER.SELECTION_FIELDS_PREFILLS.REMUNERATION_AMOUNT_PARTIAL' : 'ORDER.SELECTION_FIELDS_PREFILLS.REMUNERATION_AMOUNT')
    };

    if (this.isSelection) {
      newValue['warrantyPeriod'] = this.translateService.instant('ORDER.SELECTION_FIELDS_PREFILLS.WARRANTY_PERIOD');
    }

    this.clientAndContactCtrl.patchValue(newValue);
  }

  private buildClientNeedFormGroup() {
    const fg = new FormGroup({
      salaryInfo: new FormControl(''),
      salaryMatrix: this.buildSalaryFormGroup(),
      accessToAccessories: new FormControl(false),
      periods: new UntypedFormControl({ value: this._getPeriodPrefill() }),
      shortage: new FormControl(0, [Validators.required]),
      canIncreaseSalary: new FormControl(false),
      passCommentToOrder: new FormControl(false),
      canGetBonuses: new FormControl(false),
      opportunityToEmployDirectly: new FormControl(false),
      candidatesToBeProvided: new FormControl(undefined),
      orderCanBeExtended: new FormControl(false),
      orderCanBeExtendedComment: new FormControl(''),
      canEmployUa: new FormControl(false),
    });

    this.subscriptions.push(
      fg.valueChanges.subscribe(() => {
        window.setTimeout(() => {
          this.invalidControls.step2 = Object.keys(fg.controls)
            .filter(control => fg.controls[control].status !== 'VALID')
            .map(control => control.split(/(?=[A-Z])/).join(' ').toLowerCase())
            .join(', ');
        }, 0);
      })
    );

    return fg;
  }

  private buildSalaryFormGroup() {
    const fg = new FormGroup({
        hourlyBruttoFrom: new FormControl(undefined),
        hourlyBruttoTo: new FormControl(undefined),
        hourlyNettoFrom: new FormControl(undefined),
        hourlyNettoTo: new FormControl(undefined),
        monthlyBruttoFrom: new FormControl(undefined),
        monthlyBruttoTo: new FormControl(undefined),
        monthlyNettoFrom: new FormControl(undefined),
        monthlyNettoTo: new FormControl(undefined),

        hourlyBruttoFromFilled: new FormControl(false),
        hourlyBruttoToFilled: new FormControl(false),
        hourlyNettoFromFilled: new FormControl(false),
        hourlyNettoToFilled: new FormControl(false),
        monthlyBruttoFromFilled: new FormControl(false),
        monthlyBruttoToFilled: new FormControl(false),
        monthlyNettoFromFilled: new FormControl(false),
        monthlyNettoToFilled: new FormControl(false),

        useInTemplate: new FormControl(undefined),
        description: new FormControl(undefined),
        passDescriptionToTemplate: new FormControl(undefined)
    }, [hasBruttoValue(), hasBruttoValueForUseInDoc()]);
    return fg;
  }

  private buildSpecificationFormGroup() {
    const tagsArr = [].concat(...Object.values(this.tagGroupMap));
    const tags = {};
    tagsArr.map( t => tags[t] = []);
    const fg = new FormGroup({
      positionTitle: new FormControl('', [Validators.required]),
      personalProtectiveEquipmentBiuro: new FormControl(undefined, []),
      personalProtectiveEquipmentClient: new FormControl(undefined, []),
      isQualificationImprovementPossible: new FormControl(false, [Validators.required]),
      clientWashesClothes: new FormControl(false, [Validators.required]),
      additionalClientTrainings: new FormControl(false, [Validators.required]),
      scheduleTypes: new FormControl([]),
      scheduleText: new FormControl(undefined, [Validators.required]),
      address: new FormControl('', [Validators.required]),
      isSecurityPostPresent: new FormControl(false, [Validators.required]),
      assignmentTags: new FormControl(tags),
      riskFactors: new FormControl(undefined),
      minExperienceMonths: new FormControl(0, [Validators.required]),
      experienceType: new FormControl(ExperienceTypes.irrelevant, [Validators.required]),
      ageFrom: new FormControl(18),
      ageTo: new FormControl(100),
      male: new FormControl(true),
      female: new FormControl(true),
      mandatoryRequirements: new FormControl(undefined),
      optionalRequirements: new FormControl([]),
      certificates: new FormControl(undefined),
      workFunctions: new FormControl(undefined),
      companyRepresentation: new FormControl(undefined),
      requirements: new FormControl(undefined),
      additional_info: new FormControl(undefined),
      competences: new FormControl(undefined),
      agencyProcessStandart: new FormControl(undefined),
      selectionProcess: new FormControl(undefined),
      workConditions: new FormControl(undefined),
      competences2: new FormControl(undefined),
      clientInterviewId: new FormControl(undefined),
      intensity: new FormControl(undefined, [Validators.required]),
      onboardingDuration: new FormControl(0, [Validators.required]),
      isWorkplaceVideoAllowed: new FormControl(true),
      careerPossibilities: new FormControl(undefined),
      requirementsOther: new FormControl(undefined),
      qualification: new FormControl(QualificationEnum.NonSpecialist),
      additionalRecruitementInformation: new FormControl(undefined)
    });

    this.subscriptions.push(
      fg.valueChanges.subscribe(() => {
        window.setTimeout(() => {
          this.invalidControls.step3 = Object.keys(fg.controls)
            .filter(control => fg.controls[control].status !== 'VALID')
            .map(control => control.split(/(?=[A-Z])/).join(' ').toLowerCase())
            .join(', ');
        }, 0);
      })
    );

    return fg;
  }

  getOrderData(): OrderInput {
    const clientAndContact = this.clientAndContactCtrl.value;
    const clientNeed = this.clientNeedCtrl.value;
    const recruitmentProgressReportFrequency = this.recruitmentProgressReportFrequencyCtrl.value;
    let reportingFrequencyStartDate = this.reportingFrequencyStartDateCtrl.value;

    if (reportingFrequencyStartDate) {
      reportingFrequencyStartDate = moment(reportingFrequencyStartDate).format('YYYY-MM-DD');
    }

    let specId: string;

    if (this.createdSpecification?.id) {
      specId = this.createdSpecification.id;
    } else {
      specId = this.selectedOrder?.specification?.id;
    }

    let result: OrderInput = {
      additionalClientContacts: clientAndContact.additionalContacts.map(contactId => atob(contactId).split(':')[1]),
      cancellationPeriod: clientAndContact.cancellationPeriod,
      projectManager: clientAndContact.projectManager,
      recruiter: clientAndContact.recruiter,
      biuroSignee: clientAndContact.biuroSignee,
      additionalRegions: clientAndContact.additionalRegions, //?.map(it => btoa(`RegionType:${it}`)) ?? [],
      compensationForRecruitment: clientAndContact.compensationForRecruitment,
      contract: clientAndContact.contract,
      region: atob(clientAndContact.region).split(':')[1],
      shortage: clientNeed.shortage,
      salaryInfo: clientNeed.salaryInfo,
      company: this.relayIdService.parseId(this.orderCreateService.selectedCompany.value.id).toString(),
      workwearIssuedBy: WorkwearIssuedBy.client,
      clientContact: atob(clientAndContact.clientContact).split(':')[1],
      specification: specId,
      orderType: this.orderForm.get('orderType').value,
      trafficGeneration: this.orderForm.get('trafficGeneration').value,
      canIncreaseSalary: clientNeed.canIncreaseSalary,
      passCommentToOrder: clientNeed.passCommentToOrder,
      canGetBonuses: clientNeed.canGetBonuses,
      isLogoPublicAllowed: clientAndContact.isLogoPublicAllowed,
      isCompanyInternational: clientAndContact.isCompanyInternational,
      isCompanyModern: clientAndContact.isCompanyModern,
      opportunityToEmployDirectly: clientNeed.opportunityToEmployDirectly,
      orderCanBeExtended: clientNeed.orderCanBeExtended,
      orderCanBeExtendedComment: clientNeed.orderCanBeExtendedComment,
      language: this.orderForm.get('language')?.value,
      minVsdfPayment: clientAndContact.minVsdfPayment,
      otherInfo: clientAndContact.otherInfo,
      workTimeRegistrationSystem: clientAndContact.workTimeRegistrationSystem,
      orderNumber: this.orderForm.get('orderNumber')?.value,
      subtype: this.orderForm.get('subtype')?.value,
      createdFromBlank: this.createdFromBlank,
      qualification: this.specificationCtrl.value.qualification,
      displayPlannedTermForProvision: clientAndContact.displayPlannedTermForProvision,
      exportFreetextRequirements: clientAndContact.exportFreetextRequirements,
      recruitmentProgressReportFrequency,
      reportingFrequencyStartDate
    };
    if (this.isSelection || this.isPartialSelection) {
      result = {
        ...result,
        candidatesToBeProvided: clientNeed.candidatesToBeProvided,
        plannedTermForProvision: clientAndContact.plannedTermForProvision,
        provisionOfInformationOnConclusion: clientAndContact.provisionOfInformationOnConclusion,
        remunerationAmount: clientAndContact.remunerationAmount,
        warrantyPeriod: clientAndContact.warrantyPeriod
      };
    }
    return result;
  }

  getPeriodsData(withId = false) {
    const fmt = 'YYYY-MM-DD';
    return this.formPeriods.value?.map(p => {
        return {
          id: withId ? p.id : null,
          dateFrom: moment(p.dateFrom).format(fmt),
        dateTo: moment(p.dateTo).isValid() ? moment(p.dateTo).format(fmt) : null,
          demand: p.demand,
        longTerm: p.longTerm
        };
    }) ?? [];
  }

  submitForm(redirectToEdit: boolean | string = false) {
    if (this.isFormValid()) {
      this.loading = true;

      if (this.editOrderId) {
        this.saveClientInterview(this._getRelayId());
        this.updateSpecification();
      } else {
        this.createSpecification();
      }
    } else {
      this.showErrors();
      console.log(this.orderForm.getRawValue());
    }
  }

  isFormValid(): boolean {
    if (this.clientNeedCtrl.valid && this.specificationCtrl.valid) {
      return true;
    }
    return false;
  }

  private updateSpecification() {
    if (this.specificationCtrl) {
      const specInput: SpecificationModelUpdateMutationInput = {
        id: btoa(`SpecificationType:${this.selectedOrder?.specification?.id}`),
        ...this.getSpecData()
      };
      this.updateSpecGQL.mutate({input: specInput})
        .subscribe(resp => {
          this.createOrder();
          this.selectedOrder.specification.tagassignmentSet = resp.data.specificationUpdate.specification.tagassignmentSet;
          this.selectedOrder.specification.salary = resp.data.specificationUpdate.specification.salary;
        }, err => {
          this.loading = false;
          this.notify.notify(`Could not create order. ERROR ${err}`);
          console.log(err);
        });
    }
  }

  createOrder(redirectToEdit: boolean | string = false) {
    const orderData = this.getOrderData();
    // console.log(orderData)
    // orderData.hourRateBruttoPermanent = orderData.hourRateBruttoPermanent.replace(',', '.');
    if (this.editOrderId) {
      const periods$ = this.getPeriodsData(true);
      this.updateCompanyDescriptionGQL
        .mutate({
          id: this.orderCreateService.selectedCompany.value.id,
          description: this.descriptionCtrl.value,
          salesQuestionnaire: this.salesQuestionnaireCtrl.value
        })
        .subscribe(
          (res) => {
            this.updateOrderWithPeriods(orderData, periods$);
          },
          (err) => {
            this.loading = false;
            this.notify.notify('Klaida bandant išsaugoti įmonės aprašymą', 2);
          }
        );
    } else {
      const periods$ = this.getPeriodsData();
      this.createOrderGQL
        .mutate({ order: orderData, periods: periods$ })
        .subscribe(
          (r) => {
            this.updateCompanyDescription();
            this.loading = false;
            if (this.isSelection || this.isPartialSelection) {
              this.saveClientInterview(r.data.createOrder.order.id);
            }
            const orderId = atob(r.data.createOrder.order.id).split(':')[1];
            this.orderCreated = true;
            this.router.navigate([`/orders/edit/${orderId}`]);
            // } else if (redirectToEdit === 'pdf') {
            //   const orderId = atob(r.data.createOrder.order.id).split(':')[1];
            //   this.ordersSevice.downloadContract(orderId).subscribe(
            //     res => {
            //       this.downloadPDF(res);
            //       this.router.navigate([`/orders/edit/${orderId}`]);
            //     }
            //   );
            // } else {
            //   this.router.navigate(['/orders/list']);
            // }
          },
          (err) => {
            this.loading = false;
            this.notify.notify(`Could not create order. ERROR ${err}`);
          }
        );
    }
  }

  getSpecData(): SpecificationModelCreateMutationInput {

    const orderData = this.orderForm.getRawValue();
    const formData = this.specificationCtrl.value;

    const result: SpecificationModelCreateMutationInput = {
      company: this.relayIdService.parseIdAsCompanyType(this.orderCreateService.selectedCompany.value.id),
      positionTitle: formData.positionTitle,
      name: formData.positionTitle,
      address: formData.address,
      ageFrom: formData.ageFrom,
      ageTo: formData.ageTo,
      certificates: this.relayIdService.decodeIdForType(formData.certificates, 'CertificateType'),
      experienceType: formData.experienceType,
      female: formData.female,
      intensity: formData.intensity,
      isSecurityPostPresent: formData.isSecurityPostPresent,
      isWorkplaceVisitAllowed: orderData?.clientAndContactForm?.isWorkplaceVisitAllowed,
      male: formData.male,
      mandatoryRequirements: this.relayIdService.decodeIdForType(formData.mandatoryRequirements, 'RequirementType'),
      minExperienceMonths: formData.minExperienceMonths,
      onboardingDuration: formData.onboardingDuration,
      optionalRequirements: this.relayIdService.decodeIdForType(formData.optionalRequirements, 'RequirementType'),
      personalProtectiveEquipmentBiuro: this.relayIdService.decodeIdForType(formData.personalProtectiveEquipmentBiuro, 'PersonalProtectiveEquipmentType'),
      personalProtectiveEquipmentClient: this.relayIdService.decodeIdForType(formData.personalProtectiveEquipmentClient, 'PersonalProtectiveEquipmentType'),
      scheduleText: formData.scheduleText,
      scheduleTypes: this.relayIdService.decodeIdForType(formData.scheduleTypes, 'ScheduleTypeType'),
      salary: orderData.clientNeedForm.salaryMatrix,
      isQualificationImprovementPossible: formData.isQualificationImprovementPossible,
      clientWashesClothes: formData.clientWashesClothes,
      isWorkplaceVideoAllowed: formData.isWorkplaceVideoAllowed,
      workFunctions: formData.workFunctions,
      canEmployUa: orderData.clientNeedForm.canEmployUa,
      additionalRecruitementInformation: formData.additionalRecruitementInformation
    };
    this.updatePageTitle(formData.positionTitle);
    if (this.isSelection || this.isPartialSelection) {
      result.careerPossibilities = formData.careerPossibilities;
      result.requirementsOther = formData.requirementsOther;
    } else {
      result.riskFactors = this.relayIdService.decodeIdForType(formData.riskFactors, 'RiskFactorType');
    }

    const tagAssignments = [].concat(...Object.values(formData.assignmentTags));

    result.assignmentTags = tagAssignments.map( t => {
      return { tagId: t.tagId, comment: t.comment };
    });
    return result;
  }

  createSpecification() {
    const formData = this.orderForm.getRawValue().specificationForm;
    // delete specData.additionalClientTrainings;
    // delete specData.isQualificationImprovementPossible;
    // specInput.name = formData.positionTitle;

    const specInput: SpecificationModelCreateMutationInput = this.getSpecData();
    this.createSpecGQL.mutate({
        input: specInput,
      }
    ).subscribe(
        (resp) => {
          if (!resp.errors) {
            this.createdSpecification = resp.data.specificationCreate.specification;
            this.createOrder();
          } else {
            this.loading = false;
            this.notify.notify(`Could not create order. ERROR ${resp.errors}`);
          }
      }, err => {
          this.loading = false;
          this.notify.notify(`Could not create order. ERROR ${err}`);
        }
      );
  }

  saveClientInterview(orderId?: string) {
    if (this.specificationCtrl.value?.clientInterviewId) {
      const clientInterviewData = this.clientInterViewUpdateData(orderId);
      delete clientInterviewData.clientInterview['order'];
      this.clientInterviewUpdateGQL.mutate(clientInterviewData).subscribe(res => {
        this.notify.notify(this.translate.instant('CLIENT_INTERVIEW_UPDATED'));
      });
    } else {
      const clientInterviewData = this.getClientInterviewData(orderId);
      this.clientInterviewCreateGQL.mutate({clientInterview: clientInterviewData}).subscribe(res => {
        this.notify.notify(this.translate.instant('CLIENT_INTERVIEW_CREATED'));
      });
    }
  }

  clientInterViewUpdateData(orderId: string) {
    return {
      clientInterview: {
        ...this.getClientInterviewData(orderId),
        id: this.specificationCtrl.value?.clientInterviewId
      }
    };
  }

  getClientInterviewData(orderId: string) {
    const formData = this.specificationCtrl.value;
    return {
      // workFunctions: formData.workFunctions,
      requirements: formData.requirements,
      competences1: formData.competences?.join(','),
      competences2: formData.competences2,
      // companyRepresentation: formData.companyRepresentation,
      workConditions: formData.workConditions,
      selectionProcess: formData.selectionProcess,
      agencyProcessStandart: formData.agencyProcessStandart,
      order: orderId
    };
  }


  initOrder(order: OrderType) {
    if (order) {
      this.orderForm.get('orderType').setValue(order.orderType);
      this.orderForm.get('orderNumber')?.setValue(order?.orderNumber);
      this.orderForm.get('subtype')?.setValue(order?.subtype);
      this.orderForm.get('language').setValue(order.language);
      this.orderForm.get('country').setValue(order.company.country?.id);
      this.orderForm.get('trafficGeneration').setValue(order.trafficGeneration, { emitEvent: false });
      this.clientAndContactCtrl.get('region').setValue(btoa(`RegionMType:${order.region?.id}`));
      this.clientAndContactCtrl.get('compensationForRecruitment').setValue(order.compensationForRecruitment);
      this.clientAndContactCtrl.get('company').setValue(order.company);
      this.clientAndContactCtrl.get('clientContact').setValue(order.clientContact?.id);
      this.clientAndContactCtrl.get('additionalContacts').setValue(this.getArrOfId(order.additionalClientContacts.edges.map(value => value.node)));
      this.clientAndContactCtrl.get('projectManager').setValue(order.projectManager?.id);
      this.clientAndContactCtrl.get('recruiter').setValue(order.recruiter?.id);
      this.clientAndContactCtrl.get('biuroSignee').setValue(order.biuroSignee?.id);
      this.clientAndContactCtrl.get('additionalRegions').setValue(order.additionalRegions?.map(it =>  btoa(`RegionMType:${it.id}`)));
      this.clientAndContactCtrl.get('contract').setValue(order.contract?.id);
      this.clientAndContactCtrl.get('cancellationPeriod').setValue(order.cancellationPeriod);
      this.clientAndContactCtrl.get('isLogoPublicAllowed').setValue(order.isLogoPublicAllowed);
      this.clientAndContactCtrl.get('isCompanyInternational').setValue(order.isCompanyInternational);
      this.clientAndContactCtrl.get('isCompanyModern').setValue(order.isCompanyModern);
      this.clientAndContactCtrl.get('plannedTermForProvision').setValue(order.plannedTermForProvision);
      this.clientAndContactCtrl.get('provisionOfInformationOnConclusion').setValue(order.provisionOfInformationOnConclusion);
      this.clientAndContactCtrl.get('remunerationAmount').setValue(order.remunerationAmount);
      this.clientAndContactCtrl.get('warrantyPeriod').setValue(order.warrantyPeriod);
      this.clientAndContactCtrl.get('minVsdfPayment').setValue(order.minVsdfPayment);
      this.clientAndContactCtrl.get('otherInfo').setValue(order.otherInfo);
      this.clientAndContactCtrl.get('workTimeRegistrationSystem').setValue(order.workTimeRegistrationSystem);
      this.clientAndContactCtrl.get('displayPlannedTermForProvision').setValue(order.displayPlannedTermForProvision);
      this.clientAndContactCtrl.get('exportFreetextRequirements').setValue(order.exportFreetextRequirements);
      this.clientNeedCtrl.get('orderCanBeExtended').setValue(order.orderCanBeExtended);
      this.clientNeedCtrl.get('orderCanBeExtendedComment').setValue(order.orderCanBeExtendedComment);
      this.clientNeedCtrl.get('opportunityToEmployDirectly').setValue(order.opportunityToEmployDirectly);
      this.clientNeedCtrl.get('candidatesToBeProvided').setValue(order.candidatesToBeProvided);
      this.clientNeedCtrl.get('shortage').setValue(order.shortage);
      this.clientNeedCtrl.get('salaryInfo').setValue(order.salaryInfo);
      this.clientNeedCtrl.get('canIncreaseSalary').setValue(order.canIncreaseSalary);
      this.clientNeedCtrl.get('passCommentToOrder').setValue(order.passCommentToOrder);
      this.clientNeedCtrl.get('canGetBonuses').setValue(order.canGetBonuses);
      this.specificationCtrl.get('qualification').setValue(order.qualification);
      this.specificationCtrl.get('additionalRecruitementInformation').setValue(order.specification.additionalRecruitementInformation);
      this.recruitmentProgressReportFrequencyCtrl.setValue(order.recruitmentProgressReportFrequency);
      this.reportingFrequencyStartDateCtrl.setValue(order.reportingFrequencyStartDate);

      if (order.specification) {
        const spec = order.specification;
        this.clientNeedCtrl.get('salaryMatrix').patchValue(spec?.salary);
        this.clientNeedCtrl.get('canEmployUa').setValue(spec.canEmployUa);
        this.clientAndContactCtrl.get('isWorkplaceVisitAllowed').setValue(spec.isWorkplaceVisitAllowed);
        this.specificationCtrl.get('positionTitle').setValue(spec.positionTitle);
        this.specificationCtrl.get('onboardingDuration').setValue(spec.onboardingDuration);
        this.specificationCtrl.get('intensity').setValue(spec.intensity);
        this.specificationCtrl.get('address').setValue(spec.address);
        this.specificationCtrl.get('scheduleTypes').setValue(spec.scheduleTypes?.map(st => parseInt(st.id)));
        this.specificationCtrl.get('scheduleText').setValue(spec.scheduleText);
        this.specificationCtrl.get('isSecurityPostPresent').setValue(spec.isSecurityPostPresent);
        this.specificationCtrl.get('minExperienceMonths').setValue(spec.minExperienceMonths);
        this.specificationCtrl.get('experienceType').setValue(spec.experienceType);
        this.specificationCtrl.get('ageFrom').setValue(spec.ageFrom);
        this.specificationCtrl.get('ageTo').setValue(spec.ageTo);
        this.specificationCtrl.get('female').setValue(spec.female);
        this.specificationCtrl.get('male').setValue(spec.male);
        this.specificationCtrl.get('isQualificationImprovementPossible').setValue(spec.isQualificationImprovementPossible);
        this.specificationCtrl.get('clientWashesClothes').setValue(spec.clientWashesClothes);
        this.specificationCtrl.get('isWorkplaceVideoAllowed').setValue(spec.isWorkplaceVideoAllowed);
        this.specificationCtrl.get('careerPossibilities').setValue(spec.careerPossibilities);
        this.specificationCtrl.get('requirementsOther').setValue(spec.requirementsOther);
        this.specificationCtrl.get('workFunctions').setValue(spec.workFunctions);
        this.updatePageTitle(spec.positionTitle);
      }
      if (order.clientInterview && (order.orderType === OrderOrderType.Selection || order.orderType === OrderOrderType.PartialSelections)) {
        // this.specificationCtrl.get('workFunctions').setValue(order.clientInterview.workFunctions);
        this.specificationCtrl.get('requirements').setValue(order.clientInterview.requirements);
        this.specificationCtrl.get('competences').setValue(order.clientInterview.competences1?.split(','));
        this.specificationCtrl.get('competences2').setValue(order.clientInterview.competences2);
        this.specificationCtrl.get('workConditions').setValue(order.clientInterview.workConditions);
        this.specificationCtrl.get('selectionProcess').setValue(order.clientInterview.selectionProcess);
        this.specificationCtrl.get('agencyProcessStandart').setValue(order.clientInterview.agencyProcessStandart);
        this.specificationCtrl.get('clientInterviewId').setValue(order.clientInterview.id);
        // this.specificationCtrl.get('companyRepresentation').setValue(order.clientInterview.companyRepresentation);
      }
      // this.clientAndContactCtrl.get('orderType').setValue(order.orderType);
      // this.clientAndContactCtrl.get('workwearIssuedBy').setValue(order.workwearIssuedBy);
      // this.clientAndContactCtrl.get('specification').setValue(order.specification);
    }
  }

  onCompanyChange = (val: CompanyTypeFieldsFragment) => {
    if (val) {
      if (!this.editOrderId) {
        this.clientAndContactCtrl.reset();
        this.clientAndContactCtrl.get('cancellationPeriod').setValue(DEFAULT_CANCELLATION_PERIOD);


        // this.orderForm.reset();
        this.clientNeedCtrl.reset();
        this.formPeriods.setValue(this._getPeriodPrefill());
        this.setSelectionFieldsDefaultValues();
        this.setFieldsDefaultValues();
        this.clientAndContactCtrl.get('compensationForRecruitment')?.
          setValue(this.compensationForRecruitmentPrefill);
        this.clientAndContactCtrl.get('minVsdfPayment')?.
          setValue(this.minVsdfPaymentPrefill);
        this.clientAndContactCtrl.get('workTimeRegistrationSystem')?.
          setValue(this.workTimeRegistrationSystemPrefill);

        this.companyCtrl.setValue(val);
      }
      // const periods = this.clientNeedCtrl.get('periods').value;
      // this.clientNeedCtrl.get('periods').setValue(periods);
      // this.onCompanyChangeOld(val.id, true);
      this.clientAndContactCtrl.get('description').setValue(this.orderCreateService.selectedCompany.value.description);
      if (this.orderCreateService.selectedCompany.value.salesQuestionnaire) {
        this.clientAndContactCtrl.get('salesQuestionnaire').setValue(this.orderCreateService.selectedCompany.value.salesQuestionnaire);
      }
      // this.specificationCtrl.setValue(this.buildSpecificationFormGroup());
    }
  }

  onNewOrder(orderId: string) {
    if(!this.editOrderId && orderId) {
      if(orderId !== 'CREATE_BLANK_ORDER') {
        this.getOrder(orderId, true);
        this.createdFromBlank = false;
      } else {
        this.createdFromBlank = true;
      }
    }
  }

  getArrOfId(arr: any[]) {
    const result = [];
    for (const item of arr) {
      if (item.id) {
        result.push(item.id);
      }
    }
    return result;
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s?.unsubscribe?.());
  }


  downloadOrder(orderId?: string, title?: string) {
    if(!this.selectedOrder.contract) {
      this.notify.notify(this.translate.instant('ORDER.NO_CONTRACT_ERROR'));
    } else {
      this.ordersSevice.downloadContract(this.editOrderId).subscribe(
        r => this.downloadPDF(r, `${title} - ${orderId}`),
        err => this.notify.notify(err.message)
      );
    }
  }

  downloadPDF(data: any, filename?: string) {
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename ? `${filename}.pdf` : 'order.pdf';
    link.click();
  }

  private _getPeriodPrefill() {
    return [{
      id: null,
      dateFrom: moment().format('YYYY-MM-DD'),
      dateTo: moment().add(30, 'days').format('YYYY-MM-DD'),
      demand: 1,
      longTerm: false
    }];
  }

  copyTable(element) {

    const elTable = document.querySelector('table');
    const copyEl = elToBeCopied => {
      let range, sel;

      // Ensure that range and selection are supported by the browsers
      if (document.createRange && window.getSelection) {
        range = document.createRange();
        sel = window.getSelection();
        // unselect any element in the page
        sel.removeAllRanges();

        try {
          range.selectNodeContents(elToBeCopied);
          sel.addRange(range);
        } catch (e) {
          range.selectNode(elToBeCopied);
          sel.addRange(range);
        }

        document.execCommand('copy');
      }
      sel.removeAllRanges();
    };
    copyEl(elTable);
  }

  private _getRelayId() {
    return btoa(`OrderMType:${this.editOrderId$}`);
  }

  openSelectionLogDialog() {
    const country = this.orderForm.get('country').value;
    this.orderActionLogService.openChangeLogDialog(btoa(`OrderType:${this.editOrderId$}`), country);
  }

  onSelectedChange() {
    this.clientNeeded.onPeriodChange();
  }

  private async prefillCompensationInput(langauge?) {
    const langMap = {
      LANGUAGE_LT: 'lt',
      LANGUAGE_LV: 'lv',
      LANGUAGE_EE: 'et',
      LANGUAGE_ET: 'et',
      LANGUAGE_EN: 'en',
    };
    if (this.orderForm?.get('language')?.value) {
      this.subscriptions.push(
        this.orderForm.get('language').valueChanges.subscribe(
        async language => {
          if (!this.orderId) {
            localStorage.setItem(Constants.createOrderLanguageKey, language);
          }
          const langFile = langMap[language];
          const langData = await this.translate2.getTranslation(langFile).toPromise();
          this.setLocalizedPrefills(langFile, langData);
        })
      );
    } else {
      const prefillLang = langauge ? langauge : OrderLanguageEnum.LanguageLt;
      const langData = await this.translate2.getTranslation(langMap[prefillLang]).toPromise();
      this.setLocalizedPrefills(prefillLang, langData);
    }
  }

  private updateAvailableLanguages(isoCode: string, selectedLanguage?: string) {
    if (!isoCode) {
      return;
    }
    switch (isoCode) {
      case 'lv':
        this.availableLanguages = [
          {
            key: 'LATVIAN',
            value: this.languages.LanguageLv
          },
        ];
        break;

      case 'lt':
        this.availableLanguages = [
          {
            key: 'LITHUANIAN',
            value: this.languages.LanguageLt
          }
        ];
        break;

      case 'ee':
        this.availableLanguages = [
          {
            key: 'ESTONIAN',
            value: this.languages.LanguageEt
          },
        ];
        break;

      default:
        break;
    }

    this.availableLanguages.push(
      {
        key: 'ENGLISH',
        value: this.languages.LanguageEn
      },
    );

    const isSelectedLanugageAvalable = (this.availableLanguages.filter(it => it.value === selectedLanguage).length > 0);
    if (! selectedLanguage  ||  !isSelectedLanugageAvalable){
      this.orderForm.get('language').setValue(this.availableLanguages[0].value);
    }
  }

  private setLocalizedPrefills(lang, value: any) {
    let translateKey = 'COMPENSATION_FOR_RECRUITEMENT_CONTENT';
    if (lang === 'en') {
      const selectedCountryISO = this.selectedCountry?.isoCode;
      translateKey = selectedCountryISO === 'lv' ? 'COMPENSATION_FOR_RECRUITEMENT_CONTENT_LV' :
                     selectedCountryISO === 'ee' ? 'COMPENSATION_FOR_RECRUITEMENT_CONTENT_EE' : translateKey;
    }

    const form = this.orderForm.get('clientAndContactForm');
    // const formField = form?.get('compensationForRecruitment');
    // const formField2 = form?.get('minVsdfPayment');
    // const formField3 = form?.get('workTimeRegistrationSystem');
    this.compensationForRecruitmentPrefill = value['CLIENT'][translateKey];
    this.minVsdfPaymentPrefill = value['CLIENT']['MIN_VSDF_PAYMENT'];
    this.workTimeRegistrationSystemPrefill = value['CLIENT']['WORK_TIME_REGISTRATION_SYSTEM'];


    ['compensationForRecruitment', 'minVsdfPayment', 'workTimeRegistrationSystem'].forEach(key => {
      const fc = form.get(key);
      if (fc && fc.pristine) {
        fc.setValue(this[key + 'Prefill']);
      }
    });

    //
    // if (formField && formField.pristine) {
    //   formField.setValue(this.compensationForRecruitmentPrefill);
    // }
  }

  private updatePageTitle(newTitle) {
    this.title.setTitle(newTitle);
  }

  getShowExtraSelectionFields() {
    const isSelection = this.orderTypeCtrl.value === this.orderTypes.selection;
    const isPartialSelection = this.orderTypeCtrl.value === this.orderTypes.partialSelection;
    const isCompanySelected = this.clientAndContactCtrl.get('company')?.value?.id;
    const isEditMode = this.editOrderId;
    const isRegionSelected = this.clientAndContactCtrl.get('region')?.value;
    const isEditModeAndRegionSelected = isEditMode && isRegionSelected;
    return (isSelection || isPartialSelection) && (isCompanySelected || isEditModeAndRegionSelected);
  }

  onOrderErrorClick() {
    this.dialog.open(OrderDataErrorReportsComponent, {
      data: {
        orderId: btoa(`OrderType:${ this.editOrderId }`),
        orderErrors: this.orderErrors
      },
      autoFocus: false
    }).afterClosed().subscribe((value: OrderErrorType | undefined) => {
      if (value) {
        this.orderErrors.push(value);
      }
    });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import {
  Tag,
  TagAssignment,
} from '../../../specification/tag-assignment-table/tag';
import {
  SpecificationEditGQL,
} from '../../../specification/specification-edit/graphql';

import { FormControl, FormGroup } from '@angular/forms';
import { PersonalProtectiveEquipment } from '../../../specification/ppe-issuance-table/ppe';
import { PPESelectionEvent } from '../../../specification/ppe-issuance-table/ppe-issuance-table.component';
import { TagAssignmentTableItem } from '../../../specification/tag-assignment-table/tag-assignment-table-datasource';
import { OrderTypeEnum, TagGroupKey } from '../../../shared/types/order-types';
import { OrderCreateService } from '../../../order/order-create/order-create.service';

import {
  BaseOrderFieldsFragment,
  CompanySelectFieldsFragment,
  CompetencesGQL,
  GetTagsGroupsWithPpeGQL
} from '../../../../generated/graphql';
import {takeWhile} from 'rxjs/operators';


@Component({
  selector: 'app-order-specification',
  templateUrl: './order-specification.component.html',
  styleUrls: ['./order-specification.component.scss'],
})
export class OrderSpecificationComponent implements OnInit {
  private $form: FormGroup;
  country: string;

  @Input() clientAndContactForm: FormGroup;
  @Input() orderForm: FormGroup;
  @Input() set form(value: FormGroup) {
    this.$form = value;
    this.$form.valueChanges.pipe(takeWhile(() => value.pristine)).subscribe(v => {
      const hasAnyValue = [
          'workFunctions',
          'competences2',
          'workConditions',
          'selectionProcess',
          'agencyProcessStandart']
            .map(key => !!value.get(key)?.value)
            .reduce((a, b) => a || b, false);
      this.showHints = !hasAnyValue;
    });
  }
  get form() {
    return this.$form;
  }
  orderTypes = OrderTypeEnum;
  private $orderType: OrderTypeEnum;
  @Input() set orderType(value: OrderTypeEnum) {
    this.$orderType = value;
  }
  get orderType() {
    return this.$orderType;
  }

  $order: BaseOrderFieldsFragment;
  @Input() set order(val: BaseOrderFieldsFragment) {
    this.$order = val;
    if (val?.specification) {
      this.ppeIssuedByAgency = val.specification.personalProtectiveEquipmentBiuro.map(
        (it) => it.id
      );
      this.ppeIssuedByClient = val.specification.personalProtectiveEquipmentClient.map(
        (it) => it.id
      );
      this.tagAssignments = val.specification.tagassignmentSet;
    }
  }
  get order(){
    return this.$order;
  }

  $selectedCountry = null;
  @Input() set selectedCountry(country) {
    if (country?.id) {
      this.$selectedCountry = country;
      this.getCompetences(this.$selectedCountry?.id);
    }
  }

  get passRequirementsCtrl() { return this.clientAndContactForm.controls['exportFreetextRequirements'] as FormControl; }

  get recruitmentProgressReportFrequencyCtrl() {
    return this.orderForm && this.orderForm.get('recruitmentProgressReportFrequency') as FormControl;
  }

  get reportingFrequencyStartDateCtrl() {
    return this.orderForm && this.orderForm.get('reportingFrequencyStartDate') as FormControl;
  }

  tagGroupMap = TagGroupKey;
  tagAssignments: TagAssignment[] = [];
  tagsGroupDict: { [key: string]: Tag[] } = {};
  personalProtectiveEquipment: PersonalProtectiveEquipment[];
  ppeIssuedByClient: string[] = [];
  ppeIssuedByAgency: string[] = [];
  competences = [];
  clientInterviewInProgress = false;
  showHints = true;
  constructor(
    private specificationGQL: SpecificationEditGQL,
    private dataForNewSpecificationGQL: GetTagsGroupsWithPpeGQL,
    private createOrderService: OrderCreateService,
    private competencesGQL: CompetencesGQL
  ) {}

  ngOnInit(): void {
    this.createOrderService.selectedCompany
    .subscribe(company => {
        this.country = company?.country?.id;
        if (this.country) {
          this.loadSpecificationData(null, company?.country?.id);

        }

      });
  }

  showErrorForSection(sectionId: number) {
    switch (sectionId) {
      case 0:
        return (
          (
            (this.orderType === this.orderTypes.selection || this.orderType === this.orderTypes.partialSelection) &&
            (
              this.form.get('positionTitle').hasError('required') ||
              this.form.get('workFunctions').hasError('required')
            )
          )
          ||
          (
            this.orderType === this.orderTypes.staffing &&
            (
              this.form.get('positionTitle').hasError('required') ||
              this.form.get('intensity').hasError('required') ||
              this.form.get('workFunctions').hasError('required') ||
              this.form.get('onboardingDuration').hasError('required') ||
              this.form.get('address').hasError('required') ||
              this.form.get('scheduleTypes').hasError('required') ||
              this.form.get('scheduleText').hasError('required')
            )
          )
        );
      case 1:
        return (
          this.form.get('minExperienceMonths').hasError('required') ||
          this.form.get('experienceType').hasError('required')
        );
      case 2:
          return false;
      case 5:
        return (
            (this.orderType === this.orderTypes.selection || this.orderType === this.orderTypes.partialSelection) &&
            (
              this.form.get('intensity').hasError('required') ||
              this.form.get('onboardingDuration').hasError('required') ||
              this.form.get('address').hasError('required') ||
              this.form.get('scheduleTypes').hasError('required') ||
              this.form.get('scheduleText').hasError('required')
            )
          );
      case 6:
        return (
          (this.orderType === this.orderTypes.selection || this.orderType === this.orderTypes.partialSelection) &&
            (
              this.form.get('agencyProcessStandart').hasError('required') ||
              this.form.get('selectionProcess').hasError('required')
            )
          );
      default:
        return false;
    }
  }

  onSelectedChange($event: PPESelectionEvent) {
    this.form
      .get('personalProtectiveEquipmentBiuro')
      .setValue($event.agency.map((it) => it.id));
    this.form
      .get('personalProtectiveEquipmentClient')
      .setValue($event.client.map((it) => it.id));
  }

  onTagsSelection($event: TagAssignmentTableItem[], groupKey: string) {
    const assignments = this.form.get('assignmentTags').value;
    assignments[groupKey] = $event;
  }

  private getCompetences(countryId: string) {
    this.competencesGQL
      .fetch({country: countryId}, { fetchPolicy: 'cache-first' })
      .subscribe((res: any) => {
        if (res.data) {
          this.competences = res.data.competences.edges;
        }
      });
  }

  private loadSpecificationData(specId?: number, countryId?: string) {
    if (specId) {
      this.specificationGQL
        .fetch({ id: specId }, { fetchPolicy: 'no-cache' })
        .subscribe(
          (resp) => {
            const data = resp.data;
            Object.keys(this.tagsGroupDict).forEach(key => this.tagsGroupDict[key].length = 0);
          },
          (err) => {
            console.log(err);
          }
        );
    } else {
      this.dataForNewSpecificationGQL
        .watch({ country: countryId }, { fetchPolicy: 'cache-and-network' })
        .valueChanges
        .subscribe(
          (resp) => {
            const data = resp.data;

            Object.keys(this.tagsGroupDict).forEach(key => this.tagsGroupDict[key].length = 0);
            data.tagsGroups.forEach((group) => {
              this.tagsGroupDict[group.key] = JSON.parse(JSON.stringify(group.tagSet));
            });
            this.personalProtectiveEquipment = data.personalProtectiveEquipment;
          },
          (err) => {
            console.log(err);
          }
        );
    }
  }

  requirementsSelectionChange(event) {
    if (event.checked) {
      this.passRequirementsCtrl.setValue(true);
    }
  }
}

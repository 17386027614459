import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApolloQueryResult } from '@apollo/client/core';

import {
  CandidatesTableDataGQL, CandidatesTableDataQuery, GetCandidatesGQL, GetCandidatesQuery
} from '../../generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class CandidatesService {

  constructor(
    private candidatesTableDataGQL: CandidatesTableDataGQL,
    private getCandidatesGQL: GetCandidatesGQL
  ) { }

  getTableData(filters?): Observable<ApolloQueryResult<CandidatesTableDataQuery>> {
    return this.candidatesTableDataGQL.watch(filters, { fetchPolicy: 'no-cache' }).valueChanges;
  }

  getCandidatesTableData(filters): Observable<ApolloQueryResult<GetCandidatesQuery>> {
    return this.getCandidatesGQL.fetch(filters, { fetchPolicy: 'no-cache' });
  }
}

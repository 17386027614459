<div class='form-container'>
  <div class='header-and-close-btn'>
    <span class='header'>
      {{ "CREATE_LINK" | translate }}
    </span>
    <mat-icon (click)='closeCreationDialog()'>close</mat-icon>
  </div>
  <form [formGroup]='externalLinkForm'>
    <mat-form-field appearance="outline">
      <mat-label>{{ "TITLE" | translate }}</mat-label>
      <input formControlName='title' matInput>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ "URL" | translate }}</mat-label>
      <input formControlName='url' matInput>
    </mat-form-field>
    <div class='btn-handler'>
      <button
        [disabled]='externalLinkForm.invalid'
        mat-flat-button
        color='accent'
        class='create-button'
        (click)='closeDialogOnCreate()'
      >
        {{ "CREATE" | translate }}
      </button>
    </div>
  </form>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { StandupDashboardServiceService } from '../standup-dashboard-service.service';
import { MatDialog } from '@angular/material/dialog';
import { LinkCreateDialogComponent } from './link-create-dialog/link-create-dialog.component';
import { DashboardType } from '../../../../generated/graphql';

@Component({
  selector: 'app-standup-links',
  templateUrl: './standup-links.component.html',
  styleUrls: ['./standup-links.component.scss']
})
export class StandupLinksComponent implements OnInit {

  @Input() currentDashboard: DashboardType;
  @Input() links;

  constructor(
    private standupService: StandupDashboardServiceService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  openCreationDialog() {
    this.dialog.open(LinkCreateDialogComponent, {
      width: '300px',
      panelClass: 'rounded-dialog-16',
    }).afterClosed().subscribe(result => {
      if (result) {
        this.createExternalLink(this.currentDashboard.id, result.url, result.title);
        this.links.push({ url: result.url, title: result.title });
      }
    });
  }

  private createExternalLink(dashboard: string, url: string, title: string) {
    this.standupService.createCustomLink({ title, url, dashboard }).subscribe();
  }
}

import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegionService {

  constructor(private http: HttpClient) { }

  getRegions(): Observable<Region[]> {
    const url =  'https://boris-api.workis.lt/api/v1/pms/counts/';
    return this.http.get<Region[]>(url);
  }

  updatePMCount(count, regionId): Observable<Region> {
    const url =  'https://boris-api.workis.lt/api/v1/pms/counts/';
    return this.http.post<Region>(url, {region: regionId, value: count});
  }

  configureBaseUrlByRegion(countryCluster?: string): string {
    const host = window.location.host;
    let country = '';

    if (host.includes('.lt')) {
      country = 'lt';
    } else if (host.includes('.lv')) {
      country = 'lv';
    } else if (host.includes('.ee')) {
      country = 'ee';
    } else {
      country = 'lt';
    }

    const urlPrefix = environment['livasAPIBase' + country.toUpperCase()];
    const isUrlLangSlashNeeded = urlPrefix[urlPrefix.length - 1] !== '/';

    return `${urlPrefix}${isUrlLangSlashNeeded ? '/' : ''}${countryCluster ? countryCluster + '/' : ''}`;
  }
}

export interface Region {
  region: number;
  region_title: string;
  value?: number;
}

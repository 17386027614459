import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { CalendarService } from 'src/app/calendar/calendar.service';
import { AbsencesService } from '../absences.service';

@Component({
  selector: 'app-absences-dialog',
  templateUrl: './absences-dialog.component.html',
  styleUrls: ['./absences-dialog.component.scss'],
})
export class AbsencesDialogComponent implements OnInit, OnDestroy {
  id: any;
  title: string;
  headline: string;
  absences: Array<any>;
  employers: Array<any>;
  absenceForm: FormGroup;
  deleteForm: FormGroup;
  records: Array<any>;
  reasons: Array<any>;
  isPayable: boolean;
  isUpdateMode: boolean;
  permissions: {};
  createAbsence: any;
  deleteAbsence: any;
  permission: any;
  form: any;
  employeeId: number;
  subscriptions: any;
  sodraData: Array<any>;
  periodAbsences = {};
  daysColors: {};

  step = 1;

  isDeletable = false;
  isUpdatable = false;
  isCreatable = false;
  isConditional = false;

  isLoading = false;
  isActionButtonDisabled = false;

  isUpdatePreSave = false;

  shiftsSelection: SelectionModel<number>;

  minDate: Date;
  maxDate: Date;

  isFullyUnpaid = false;
  isPartialUnpaid = false;

  showVacations = false;
  unusedVacations: number;

  locale = 'en';

  constructor(
    private fb: FormBuilder,
    public absencesService: AbsencesService,
    public dialog: MatDialogRef<AbsencesDialogComponent>,
    private activatedRoute: ActivatedRoute,
    private calendarService: CalendarService,
    @Inject(MAT_DIALOG_DATA) data: {
      title?: string;
      headline?: string;
      absences?: Array<any>;
      employers?: Array<any>;
      absenceForm?: FormGroup;
      records?: Array<any>;
      isUpdatePreSave?: boolean;
      showVacations?: boolean;
    }
  ) {
    this.title = data.title;
    this.headline = data.headline;
    this.absences = data.absences;
    this.employers = data.employers;
    this.absenceForm = data.absenceForm;
    this.records = data.records;
    this.employeeId = data.absenceForm.value.employeeId;
    this.showVacations = data.showVacations;
    this.setPermission = this.setPermission.bind(this);
    this.fetchRecords = this.fetchRecords.bind(this);
    this.validateDates = this.validateDates.bind(this);
    this.subscriptions = {};
    this.isUpdatePreSave = data.isUpdatePreSave;

    this.fetchRecords();

    this.shiftsSelection = new SelectionModel<number>(true, []);

    this.deleteForm = this.fb.group({
      shiftsAction: false,
    });

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.locale) {
        const locale = params.locale === 'ee' ? 'et' : params.locale;
        this.locale = locale;
      }
    });

    if (this.showVacations) {
      absencesService.getUnusedVacations([this.employeeId], data.absenceForm.value.from, data.absenceForm.value.employerId).subscribe(res => {
        this.unusedVacations = res[0].unsedVacations;
      });
    }
  }

  setTypeProps(type: any) {
    const absence = this.absences.find((a) => a.value === type);

    if (!absence) {
      return;
    }

    this.isPayable = !!absence.payable;
    this.reasons = absence.reasons;
  }

  isTypeDisabled(type: any) {
    const absence = this.absences.find((a) => a.value === type);

    return absence && absence.disabled;
  }

  validateDates(prop) {
    const form = this.absenceForm.getRawValue();
    const isInvalid = moment(form.from) > moment(form.to);

    if (prop === 'from' && isInvalid) {
      this.absenceForm.patchValue({
        to: form.from,
      });
    } else if (prop === 'to' && isInvalid) {
      this.absenceForm.patchValue({
        from: form.to,
      });
    } else {
      this.fetchRecords();
    }
  }

  fetchSodraData() {
    const form = this.absenceForm.getRawValue();
    const from = moment(form.from).format('YYYY-MM-DD');
    const to = moment(form.to).format('YYYY-MM-DD');

    this.absencesService.getSodraData(from, to, form.employerId, [this.employeeId]).subscribe((res) => {
      this.sodraData = res?.results?.rows?.[this.employeeId] || [];
    });
  }

  fetchRecords() {
    this.isLoading = true;
    const form = this.absenceForm.getRawValue();
    const date_from = moment(form.from).format('YYYY-MM-DD');
    const date_to = moment(form.to).format('YYYY-MM-DD');

    this.fetchSodraData();

    this.absencesService.getRecords([this.employeeId], date_from, date_to)
      .subscribe((res) => {
        const results = res?.results?.rows?.[this.employeeId] || {};

        this.records = {
          ...this.records,
          ...results,
        };

        this.setPermission();

        this.isLoading = false;
      });


    this.calendarService.getCalendarDaysInPeriod(date_from, date_to).subscribe(res => {
      this.daysColors = {};

      for (const day in res) {
        if (res[day]) {
          this.daysColors[day] = this.calendarService.getTypeColor(res[day]);
        }
        }
    });
  }

  getWeekday(date: string) {
    const day = moment(date).locale(this.locale);

    return day.format('dddd');
  }

  setPermission() {
    const form = this.absenceForm.getRawValue();
    const range = this.absencesService.getRange(form.from, form.to);

    this.permissions = this.employers.reduce(
      (res, employer) => ({
        ...res,
        ...{
          [employer.value]: {
            label: employer.label,
            isCreatable: true,
            isUpdatable: true,
            isDeletable: true,
            isConditional: true,
            absences: [],
            shifts: [],
          },
        },
      }),
      {}
    );

    this.periodAbsences = {};

    range.forEach((date) => {
      const record = this.records[date];

      if (!record) {
        return;
      }

      if (record.permissions) {
        for (const employerId of Object.keys(record.permissions)) {
          const permissions = record.permissions[employerId];

          if (!permissions.create && this.permissions[employerId]) {
            this.permissions[employerId].isCreatable = false;
          }

          if (!permissions.update && this.permissions[employerId]) {
            this.permissions[employerId].isUpdatable = false;
          }

          if (!permissions.delete && this.permissions[employerId]) {
            this.permissions[employerId].isDeletable = false;
          }
        }
      }

      if (record.absences) {
        for (const employerId of Object.keys(record.absences)) {
          const absences = record.absences[employerId];

          if (this.isUpdatePreSave && absences.length && this.permissions[employerId]) {
            this.permissions[employerId].isDeletable = false;
          }

          absences.forEach((absence) => {
            if (this.permissions[employerId] && this.permissions[employerId].absences) {

              this.periodAbsences[date] = {
                absence_type: absence.absence_type,
                unpaid: !!absence.unpaid,
              };

              this.permissions[employerId].absences.push({
                id: absence.id,
                date,
                absence_type: absence.absence_type,
                unpaid: absence.unpaid,
              });
            }
          });
        }
      } else {
        this.periodAbsences[date] = {
          absence_type: '',
          unpaid: false,
        };
      }

      if (record.shifts) {
        this.employers.forEach((employer) => {
          if (!record.shifts[employer.value]) {
            this.permissions[employer.value].isConditional = false;
          }
        });

        for (const employerId of Object.keys(record.shifts)) {
          const shifts = record.shifts[employerId];

          shifts.forEach((shift) => {
            if (this.permissions[employerId] && this.permissions[employerId].shifts) {
              this.permissions[employerId].shifts.push({ ...shift, ...{ date } });
            }
          });
        }
      } else {
        this.employers.forEach((employer) => {
          this.permissions[employer.value].isConditional = false;
        });
      }
    });

    this.setActionPermissions(form.employerId);

    this.setActionButtonDisabled();

    this.setUnpaidCheckbox();
  }

  setUnpaidCheckbox() {
    const unpaidAbsences = Object.keys(this.periodAbsences).filter(abs => this.periodAbsences[abs].unpaid);

    this.isFullyUnpaid = Object.keys(this.periodAbsences).length === unpaidAbsences.length;
    this.isPartialUnpaid = unpaidAbsences.length && !this.isFullyUnpaid ? true : false;
  }

  isAbsenceUnpaid(date: string) {
    return (this.periodAbsences[date] || {}).unpaid;
  }

  toggleAbsenceUnpaid(date: string) {
    if (!this.periodAbsences[date]) {
      return;
    }

    this.periodAbsences[date].unpaid = !this.periodAbsences[date].unpaid;

    this.setUnpaidCheckbox();
  }

  toggleMultipleAbsenceUnpaid(unpaid) {
    for (const date of Object.keys(this.periodAbsences)) {
      this.periodAbsences[date] = {
        ...this.periodAbsences[date],
        unpaid,
      }
    }
  }

  handleCreateAbsence() {
    const shifts = this.permission.shifts.reduce((res, shift) => {
      res[shift.id] = this.shiftsSelection.selected.indexOf(shift.id) !== -1;
      return res;
    }, {});

    const form = this.absenceForm.getRawValue();

    this.dialog.close({ event: 'create', form, shifts, periodAbsences: this.periodAbsences });
  }

  handleDeleteAbsence() {
    if (this.step === 1) {
      this.step = 2;
    } else if (this.step === 2) {
      const deleteForm = this.deleteForm.getRawValue();

      this.dialog.close({ event: 'delete', form: this.absenceForm.getRawValue(), shiftsAction: deleteForm.shiftsAction });
    }
  }

  setActionPermissions(employerId: number) {
    if (!this.permissions[employerId]) {
      return;
    }

    this.permission = this.permissions[employerId];

    this.isDeletable = this.permission.isDeletable;
    this.isUpdatable = this.permission.isUpdatable;
    this.isCreatable = this.permission.isCreatable;
    this.isConditional = this.permission.isConditional;

    this.shiftsSelection.clear();

    const selection = this.permission.shifts
      .filter((s: any) => !s.arrival)
      .map((s) => s.id);

    this.shiftsSelection = new SelectionModel<number>(true, selection);
  }

  isAbsenceTypeDisabled(absence: any) {
    if (absence.conditional) {
      const form = this.absenceForm.getRawValue();
      return this.permissions[form.employerId] && !this.permissions[form.employerId].isConditional;
    }

    return !!absence.disabled;
  }

  setActionButtonDisabled() {
    const form = this.absenceForm.getRawValue();

    if (!form.absence_type) {
      this.isActionButtonDisabled = true;
      return;
    }

    if (!this.shiftsSelection.selected.length) {
      const absence = this.absences.find((a) => a.value === form.absence_type) || {};

      if (absence.conditional) {
        this.isActionButtonDisabled = true;
        return;
      }
    }

    this.isActionButtonDisabled = false;
  }

  getFormattedDate(date: any) {
    return moment(date).format('YYYY-MM-DD');
  }

  toggleShiftSelection(shiftId: any) {
    this.shiftsSelection.toggle(shiftId);
    this.setActionButtonDisabled();
  }

  getRemoveDates() {
    const form = this.absenceForm.getRawValue();
    return `${this.getFormattedDate(form.from)} - ${this.getFormattedDate(form.to)}`;
  }

  ngOnInit(): void {
    this.form = this.absenceForm.getRawValue();

    this.minDate = this.form.min || null;
    this.maxDate = this.form.max || null;

    this.isUpdateMode = !!this.form.id;

    ['from', 'to', 'employerId'].forEach(name => {
      if (this.isUpdateMode) {
        this.absenceForm.controls[name].disable();
      } else {
        this.absenceForm.controls[name].enable();
      }
    });

    if (this.isTypeDisabled(this.form.absence_type)) {
      this.absenceForm.controls.absence_type.disable();
      this.absenceForm.controls.reason_code.disable();
    } else {
      this.absenceForm.controls.absence_type.enable();
      this.absenceForm.controls.reason_code.enable();
    }

    this.setPermission();

    this.setTypeProps(this.form.absence_type);

    this.setActionPermissions(this.form.employerId);

    this.subscriptions.absence_type = this.absenceForm.get('absence_type').valueChanges.subscribe((type) => {
        this.setTypeProps(type);
        this.setActionButtonDisabled();

        this.absenceForm.patchValue({
          reason_code: '',
          reason_text: '',
        });
      });

    this.subscriptions.reason_code = this.absenceForm.get('reason_code').valueChanges.subscribe((code) => {
        const reason = this.reasons
          ? this.reasons.find((r) => r.value === code)
          : null;

        if (reason && reason.text) {
          this.absenceForm.controls.reason_text.enable();
          this.absenceForm.controls.reason_text.setValidators([Validators.required]);
        } else {
          this.absenceForm.patchValue({
            reason_text: '',
          });

          this.absenceForm.controls.reason_text.disable();
          this.absenceForm.controls.reason_text.removeValidators([Validators.required]);
        }
      });

    this.subscriptions.employerId = this.absenceForm.get('employerId').valueChanges.subscribe(() => {
      this.setPermission();
      this.fetchSodraData();
    });

    this.subscriptions.from = this.absenceForm.get('from').valueChanges.subscribe(() => this.validateDates('from'));
    this.subscriptions.to = this.absenceForm.get('to').valueChanges.subscribe(() => this.validateDates('to'));

    this.setActionButtonDisabled();
  }

  ngOnDestroy() {
    this.subscriptions.absence_type?.unsubscribe?.();
    this.subscriptions.employerId?.unsubscribe?.();
    this.subscriptions.from?.unsubscribe?.();
    this.subscriptions.to?.unsubscribe?.();
  }

  isDisabledReason(reason: any) {
    const DISABLED_REASONS = ['00', '01'];
    return DISABLED_REASONS.indexOf(reason.value) > -1;
  }

  isExplanationRequiredForReason(reason: any) {
    const EXPLANATION_NEEDED_REASONS = ['06'];
    return EXPLANATION_NEEDED_REASONS.indexOf(reason) > -1;
  }

  isMissingComment() {
    const absenceReason = this.absenceForm.get('reason_code').value;
    if (this.isExplanationRequiredForReason(absenceReason)) {
      return !this.absenceForm.get('reason_text').value;
    } else {
      return false;
    }
  }
}

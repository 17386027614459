import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { StandupDashboardServiceService } from '../../standup-dashboard-service.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-link-create-dialog',
  templateUrl: './link-create-dialog.component.html',
  styleUrls: ['./link-create-dialog.component.scss']
})
export class LinkCreateDialogComponent implements OnInit {

  externalLinkForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private matDialogRef: MatDialogRef<LinkCreateDialogComponent>
  ) { }

  ngOnInit(): void {
    this.externalLinkForm = this.fb.group({
      title: new FormControl('', [Validators.required]),
      url: new FormControl('', [Validators.required, Validators.pattern(/^(https?:\/\/).+/)])
    });
  }

  closeCreationDialog() {
    this.matDialogRef.close();
  }

  closeDialogOnCreate(): void {
    this.matDialogRef.close(this.externalLinkForm.value);
  }
}

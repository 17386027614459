import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-single-select-customfield',
  templateUrl: './single-select-customfield.component.html',
  styleUrls: ['./single-select-customfield.component.scss']
})
export class SingleSelectCustomfieldComponent {
  @Input() label: string;
  @Input() options: { id: number, name: string }[] = [];
  @Input() valueController: FormGroup;
}

<h1 mat-dialog-title>
  <div class="title-wrapper">
    <span>{{ 'TASK_SCHEDULING.' + (data.schedule ? 'EDIT_SCHEDULE' : 'CREATE_SCHEDULE') | translate }}</span>
    <button mat-icon-button class="close-button" (click)="onCancel()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</h1>

<div mat-dialog-content>
  <form [formGroup]="scheduleForm">
    <div fxLayout>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'TASK_SCHEDULING.RECURRENCE_PERIOD' | translate }}</mat-label>
        <mat-select formControlName="recurrencePeriod" (selectionChange)="recurrencePeriodChanged()">
          <mat-option value="DAILY">{{ 'TASK_SCHEDULING.DAILY' | translate }}</mat-option>
          <mat-option value="WEEKLY">{{ 'TASK_SCHEDULING.WEEKLY' | translate }}</mat-option>
          <mat-option value="MONTHLY">{{ 'TASK_SCHEDULING.MONTHLY' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>

      <ng-container *ngIf="scheduleForm.controls['recurrencePeriod'].value">
        <mat-form-field appearance='outline'>
          <mat-label *ngIf="scheduleForm.get('recurrencePeriod').value === 'DAILY'">
            {{ 'TASK_SCHEDULING.REPEAT_EVERY_X_DAYS' | translate }}
          </mat-label>
          <mat-label *ngIf="scheduleForm.get('recurrencePeriod').value === 'WEEKLY'">
            {{ 'TASK_SCHEDULING.REPEAT_EVERY_X_WEEKS' | translate }}
          </mat-label>
          <mat-label *ngIf="scheduleForm.get('recurrencePeriod').value === 'MONTHLY'">
            {{ 'TASK_SCHEDULING.REPEAT_EVERY_X_MONTHS' | translate }}
          </mat-label>
          <input matInput type='number' formControlName='intervalCount' />
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="scheduleForm.get('recurrencePeriod').value === 'MONTHLY'">
        <div fxLayout="column">
          <div fxLayout>
            <mat-radio-button
              [style.margin]="'14px 4px 0 0'"
              [checked]="scheduleForm.controls['weekOfMonth'].value || scheduleForm.controls['dayOfWeek'].value"
              [disabled]="true"
            ></mat-radio-button>

            <mat-form-field appearance="outline">
              <mat-label>{{ 'TASK_SCHEDULING.WEEK_OF_MONTH' | translate }}</mat-label>
              <input matInput type="number" formControlName="weekOfMonth" min="1" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{ 'TASK_SCHEDULING.DAY_OF_WEEK' | translate }}</mat-label>
              <input matInput type="number" formControlName="dayOfWeek" min="1" />
            </mat-form-field>
          </div>

          <div fxLayout>
            <mat-radio-button
              [style.margin]="'14px 4px 0 0'"
              [checked]="scheduleForm.controls['dayOfMonth'].value"
              [disabled]="true"
            ></mat-radio-button>

            <mat-form-field appearance="outline">
              <mat-label>{{ 'TASK_SCHEDULING.DAY_OF_MONTH' | translate }}</mat-label>
              <input matInput type="number" formControlName="dayOfMonth" min="1" />
            </mat-form-field>
          </div>
        </div>
      </ng-container>
    </div>

    <p>
      <strong>{{ 'TASK_SCHEDULING.START_DATE' | translate }}:</strong> {{ data.date | date }}
    </p>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'TASK_SCHEDULING.END_DATE' | translate }}</mat-label>
      <input matInput [matDatepicker]="endDatePicker" [min]="todayDate" formControlName="endDate"/>
      <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #endDatePicker></mat-datepicker>
    </mat-form-field>
  </form>
</div>

<div mat-dialog-actions align="end">
  <button mat-button (click)="onCancel()">{{ 'TASK_SCHEDULING.CANCEL' | translate }}</button>
  <button mat-raised-button color="primary" (click)="onSave()" [disabled]="!scheduleForm.valid">
    {{ 'TASK_SCHEDULING.SAVE' | translate }}
  </button>
</div>

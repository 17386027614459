<div *ngIf='valueController' [formGroup]='valueController'>
  <mat-form-field fxFlex="100" appearance='outline'>
    <mat-label>{{ label }}</mat-label>
    <mat-select formControlName='numericValue'>
      <mat-option *ngFor="let option of options" [value]="option.id">
        {{ option.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';

import { CandidatesService } from '../candidates.service';

/**
 * Data source for the CandidatesTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class CandidatesCommentTableDatasource extends DataSource<any> {
  paginator: MatPaginator;
  filters = new BehaviorSubject<any>({});
  private dataSubject = new BehaviorSubject<any[]>([]);
  loading = new BehaviorSubject<boolean>(true);
  totalCount = 0;

  constructor(
    private candidatesService: CandidatesService
  ) {
    super();
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<any> {
    const dataMutations = [
      this.filters.asObservable(),
      this.paginator.page
    ];

    const fetchData = () => {
      this.loading.next(true);
      const endCursor = btoa(`arrayconnection:${(this.paginator.pageIndex) * this.paginator.pageSize - 1}`);
      const params = {
        first: this.paginator.pageSize,
        after: endCursor,
        metricValueCommentId: this.filters.value.id
      };

      return this.candidatesService.getCandidatesTableData(params).pipe(
        map(data => {
          this.loading.next(false);
          this.totalCount = data.data.candidates.totalCount;
          return data.data.candidates.edges.map(edge => edge.node);
        }),
      );
    };

    const data$ = merge(...dataMutations).pipe(
      switchMap(fetchData)
    );

    data$.subscribe(this.dataSubject);

    return this.dataSubject.asObservable();
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() {}
}

import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {
  GetDashboardStats, GetLivasBiuro2sdCountStat, GetLivasDarbius2sdCountStat, GetLivasStats, StatNames
} from '../graphql/graphql';
import {ToolbarMessageService} from '../toolbar-message.service';
import {MatDialog} from '@angular/material/dialog';
import {SelectedByClientComponent} from './selected-by-client/selected-by-client.component';
import {EdlaAlertsComponent} from './edla-alerts/edla-alerts.component';
import {Subscription} from 'rxjs';
import * as moment from 'moment';
import {InventaPrivateEmployeesComponent} from './inventa-private-employees/inventa-private-employees.component';
import { LduFormsComponent } from './ldu-forms/ldu-forms.component';

const livasLink = 'https://livas.biuro.lt/lt/personalas.php';
const darbiusLink = 'https://livas.biuro.lt/lt2/personalas.php';
const biurogoLink = 'https://livas.biuro.lt/lt-go/personalas.php';
const livasLinkLV = 'https://livas.biuro.lv/lv/personalas.php';
const darbiusLinkLV = 'https://livas.biuro.lv/lv2/personalas.php';
const workisPeopleLink = 'https://app.workis.online/client/people?review_medical=true';
const workisContractLinkEmployee = 'https://app.workis.online/client/stats?unsigned_by_employee=true';
const workisContractLinkEmployer = 'https://app.workis.online/client/stats?unsigned_by_employer=true';
const zohoLink = 'https://desk.zoho.eu/support/workis/';

const sd1ProcessLink = 'https://www.lucidchart.com/documents/edit/b61eb605-3ae6-4b8a-90b4-87580f428da6/~Iq.noqdvnbB?shared=true';
const sd2ProcessLink = 'https://www.lucidchart.com/documents/edit/983cea26-db5b-4ab4-8e27-21713201bbc4?shared=true&';
const medicalProcess = 'https://www.lucidchart.com/documents/edit/65c425b8-9538-4bd9-8a25-a368901aaa0b/sLXthVEPWNL_?shared=true';
const contractProcess = 'https://www.lucidchart.com/documents/edit/e37083fb-bb62-48aa-845c-f7924565e668/0_0?shared=true';
const selectedByClientProcess = 'https://www.lucidchart.com/documents/edit/67e8b8b7-bc17-4760-bb5a-5c4006361834/0_0?shared=true';
const zohoProcess = 'https://www.lucidchart.com/documents/edit/36e88d54-ad1a-41d1-8c63-d60ee657cd38/ah13bOR.4goO?shared=true';

export interface DashboardCard {
  title: string;
  value?: number;
  link?: string;
  clickFn?: () => void;
  extraComponent?: any;
  extraValues?: any[];
  processLink?: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {

  lastRefresh = new Date();
  timer;

  statsSub: Subscription;
  livasStatsSub: Subscription;
  getLivasBiuro2sdCountStatSub: Subscription;
  getLivasDarbius2sdCountStatSub: Subscription;

  readonly notificationWeekdays = [0, 1, 2, 3, 4, 5, 6];

  weekdays = [
    'sekmadienį',
    'pirmadienį',
    'antradienį',
    'trečiadienį',
    'ketvirtadienį',
    'penktadienį',
    'šeštadienį'
  ];

  onDutyText1 = 'I tarp 6:00-08:00 val. importuoti Ligas už 4 praėjusias savaites (BIURO, DARBIUS) iš sodros į LIVĄ';
  onDutyText2 = 'II-V tarp 6:00-08:00 val. importuoti Ligas (BIURO, DARBIUS) iš sodros į LIVĄ';
  onDutyText3 = '';

  onDutyNoticePerWeekday = {
    0: this.onDutyText3,
    1: this.onDutyText1,
    2: this.onDutyText2,
    3: this.onDutyText2,
    4: this.onDutyText2,
    5: this.onDutyText2,
    6: this.onDutyText3,
  }

  cardsOrder = [
    StatNames.lduUrgentForms,
    StatNames.sd1CountBiuroUrgent,
    StatNames.sd1CountDarbiusUrgent,
    StatNames.sd1CountBiuro,
    // StatNames.sd2CountBiuro,
    StatNames.sd1CountDarbius,
    StatNames.sd1CountBiurogo,
    StatNames.biurolv1sdCount,
    StatNames.biurolatvia1sdCount,
    // StatNames.sd2CountDarbius,
    StatNames.medicalCertificateReviewCount,
    StatNames.contractToSignCount,
    StatNames.contractToSignCountLv,
    StatNames.selectedByClientTodayApplications,
    StatNames.selectedByClientTomorrowApplications,
    StatNames.openZohoTicketCount,
    StatNames.edlaAlerts,
    StatNames.unreadSmsCount,
    StatNames.waitingTomorrowApplications,
    StatNames.waitingTomorrowApplicationsLv,
    StatNames.unsignedByEmployeeContractCount,
    StatNames.inventaPrivateEmployees,
    StatNames.inactiveApplications,
    StatNames.lduForms,
  ];

  cardsData: {[key: string]: DashboardCard} = {
    [StatNames.sd1CountBiuro]: {
      title: 'Biuro SD1 teikti 17:00 ir 21:30',
      link: livasLink,
      processLink: sd1ProcessLink
    },
    [StatNames.sd2CountBiuro]: {
      title: 'Biuro SD2 teikti 15:45',
      link: livasLink,
      processLink: sd2ProcessLink
    },
    [StatNames.sd1CountBiuroUrgent]: {
      title: 'Biuro SD1 teikti kas valandą',
      link: livasLink,
      processLink: sd1ProcessLink
    },
    [StatNames.biurolv1sdCount]: {
      title: 'SIA Biuro SD1 teikti 21:30',
      link: livasLinkLV
    },
    [StatNames.biurolatvia1sdCount]: {
      title: 'SIA Biuro Latvia SD1 teikti 21:30',
      link: darbiusLinkLV
    },
    [StatNames.sd1CountDarbius]: {
      title: 'Darbius SD1 teikti 17:00 ir 21:30',
      link: darbiusLink,
      processLink: sd1ProcessLink
    },
    [StatNames.sd2CountDarbius]: {
      title: 'Darbius SD2 teikti 15:45',
      link: darbiusLink,
      processLink: sd2ProcessLink
    },
    [StatNames.sd1CountDarbiusUrgent]: {
      title: 'Darbius SD1 teikti kas valandą',
      link: darbiusLink,
      processLink: sd1ProcessLink
    },
    [StatNames.sd1CountBiurogo]: {
      title: 'Biuro Go SD1 teikti 17:00 ir 21:30',
      link: biurogoLink,
      processLink: sd1ProcessLink
    },
    [StatNames.medicalCertificateReviewCount]: {
      title: 'Peržiūrėti įkeltų dokumentų',
      link: workisPeopleLink,
      processLink: medicalProcess
    },
    [StatNames.contractToSignCount]: {
      title: 'Pasirašyti sutartis iki 21:20 (LT)',
      link: workisContractLinkEmployer + '&country=lt',
      processLink: contractProcess
    },
    [StatNames.contractToSignCountLv]: {
      title: 'Pasirašyti sutartis iki 21:20 (LV)',
      link: workisContractLinkEmployer + '&country=lv',
      processLink: contractProcess
    },
    [StatNames.selectedByClientTodayApplications]: {
      title: 'Kliento pasirinkti šiandienai',
      clickFn: () => {
        this.openDialog(StatNames.selectedByClientTodayApplications);
      },
      extraComponent: SelectedByClientComponent,
      processLink: selectedByClientProcess
    },
    [StatNames.selectedByClientTomorrowApplications]: {
      title: 'Kliento pasirinkti ateinančioms 2sav.',
      clickFn: () => {
        this.openDialog(StatNames.selectedByClientTomorrowApplications);
      },
      extraComponent: SelectedByClientComponent,
      processLink: selectedByClientProcess
    },
    [StatNames.openZohoTicketCount]: {
      title: 'Neperžiūrėti zoho laiškai',
      link: zohoLink,
      processLink: zohoProcess
    },
    [StatNames.edlaAlerts]: {
      title: 'EDLA pranešimai',
      clickFn: () => {
        this.openDialog(StatNames.edlaAlerts);
      },
      extraComponent: EdlaAlertsComponent
    },
    [StatNames.unreadSmsCount]: {
      title: 'Neperskaitytos SMS',
      link: livasLink
    },
    [StatNames.waitingTomorrowApplications]: {
      title: 'Kandidatų, kuriuos reikia tvirtinti',
      clickFn: () => {
        this.openDialog(StatNames.waitingTomorrowApplications);
      },
      extraComponent: SelectedByClientComponent,
      processLink: selectedByClientProcess
    },
    [StatNames.waitingTomorrowApplicationsLv]: {
      title: 'Candidates to be approved (LV)',
      clickFn: () => {
        this.openDialog(StatNames.waitingTomorrowApplicationsLv);
      },
      extraComponent: SelectedByClientComponent,
      processLink: selectedByClientProcess
    },
    [StatNames.unsignedByEmployeeContractCount]: {
      title: 'Kandidatai, nepasirašę sutarties rytojui',
      link: workisContractLinkEmployee
    },
    [StatNames.inventaPrivateEmployees]: {
      title: 'Inventa: rytojui pasirinkti privatūs darbuotojai, kurie nesukandidatavo',
      clickFn: () => {
        this.openDialog(StatNames.inventaPrivateEmployees);
      },
      extraComponent: InventaPrivateEmployeesComponent
    },
    [StatNames.inactiveApplications]: {
      title: 'Neaktyvios kandidatūros',
      clickFn: () => {
        this.openDialog(StatNames.inactiveApplications);
      },
      extraComponent: SelectedByClientComponent,
    },
    [StatNames.lduForms]: {
      title: 'LDU teikti 17:00 ir 21:30',
      clickFn: () => {
        this.openDialog(StatNames.lduForms);
      },
      extraComponent: LduFormsComponent
    },
    [StatNames.lduUrgentForms]: {
      title: 'LDU teikti NEDELSIANT',
      link: livasLink,
    }
  };


  constructor(
    private getDashboardStats: GetDashboardStats,
    private toolbarMessage: ToolbarMessageService,
    private getLivasStats: GetLivasStats,
    private getLivasBiuro2sdCountStat: GetLivasBiuro2sdCountStat,
    private getLivasDarbius2sdCountStat: GetLivasDarbius2sdCountStat,
    private dialog: MatDialog
    ) {}

  ngOnInit() {
    this.fetchStats();
    this.timer = setInterval(() => {
      this.fetchStats();
    }, 300000);
    setTimeout(() => {
      this.checkNotification();
    }, 1);
  }

  ngAfterViewInit() {
    this.toolbarMessage.setMessage(`
        <div>
            <div class="inline-block" >Šiandien (${this.weekdays[this.lastRefresh.getDay()]})</div>
            <div class="inline-block" >
                <div>1SD teikti 17:00 ir 21:30</div>
                <div>Workis kandidatų netvirtinti I-V, 6:00-8:00</div>
                <div>${this.onDutyNoticePerWeekday[this.lastRefresh.getDay()]}</span>
                <div>Sutartis pasirašyti iki 21:20</span>
            </div>
        </div>
      `);
  }

  ngOnDestroy() {
    clearInterval(this.timer);
    this.toolbarMessage.clearMessage();
    if (this.statsSub && this.statsSub?.unsubscribe) {
      this.statsSub?.unsubscribe?.();
    }
    if (this.livasStatsSub && this.livasStatsSub?.unsubscribe) {
      this.livasStatsSub?.unsubscribe?.();
    }
    if (this.getLivasBiuro2sdCountStatSub && this.getLivasBiuro2sdCountStatSub?.unsubscribe) {
      this.getLivasBiuro2sdCountStatSub?.unsubscribe?.();
    }
    if (this.getLivasDarbius2sdCountStatSub && this.getLivasDarbius2sdCountStatSub?.unsubscribe) {
      this.getLivasDarbius2sdCountStatSub?.unsubscribe?.();
    }
    this.toolbarMessage.setOnDutyNotification(false);
  }

  handleCardClick(card: DashboardCard) {
    if (card.link) {
      window.open(card.link, '_blank');
    } else if (card.clickFn) {
      card.clickFn();
    }
  }

  fetchStats() {


    this.statsSub = this.getDashboardStats.fetch({}, {fetchPolicy: 'no-cache'}).subscribe(data => {
      Object.keys(data.data).forEach(key => {
        if (this.cardsData[key]) {
          if (data.data[key].length !== undefined) {
            this.cardsData[key].value = data.data[key].length;
            this.cardsData[key].extraValues = data.data[key];
          } else {
            this.cardsData[key].value = data.data[key];
          }
        }
      });
      this.lastRefresh = new Date();
    }, err => {
      Object.keys(this.cardsData).forEach(key => {
        this.cardsData[key].value = undefined;
      });
    });

    this.livasStatsSub = this.getLivasStats.fetch({}, {fetchPolicy: 'no-cache'}).subscribe(data => {
      const res = data.data.livasData;
      this.cardsData[StatNames.edlaAlerts].extraValues = res[StatNames.edlaAlerts];
      this.cardsData[StatNames.lduForms].extraValues = res[StatNames.lduForms];
      let edlaAlertCount = 0;
      res[StatNames.edlaAlerts].forEach(e => {
        e.employees.forEach(emp => edlaAlertCount++);
      });
      Object.keys(res).forEach(key => {
        if (this.cardsData[key] && (!([StatNames.edlaAlerts, StatNames.lduForms] as string[]).includes(key)) ) {
          this.cardsData[key].value = res[key];
        }
      });
      this.cardsData[StatNames.edlaAlerts].value = edlaAlertCount;
      this.cardsData[StatNames.lduForms].value = res[StatNames.lduForms].length;
    }, err => {
      this.cardsData[StatNames.edlaAlerts].value = undefined;
      this.cardsData[StatNames.unreadSmsCount].value = undefined;
      this.cardsData[StatNames.sd1CountBiuro].value = undefined;
      this.cardsData[StatNames.sd1CountDarbius].value = undefined;
      this.cardsData[StatNames.sd1CountBiurogo].value = undefined;
      this.cardsData[StatNames.lduForms].value = undefined;
    });

    // this.getLivasBiuro2sdCountStatSub = this.getLivasBiuro2sdCountStat.fetch({}, {fetchPolicy: 'no-cache'}).subscribe(data => {
    //   const res = data.data.livasData;
    //   this.cardsData[StatNames.sd2CountBiuro].value = res[StatNames.sd2CountBiuro];
    // }, err => {
    //   this.cardsData[StatNames.sd2CountBiuro].value = undefined;
    // });
    //
    // this.getLivasDarbius2sdCountStatSub = this.getLivasDarbius2sdCountStat.fetch({}, {fetchPolicy: 'no-cache'}).subscribe(data => {
    //   const res = data.data.livasData;
    //   this.cardsData[StatNames.sd2CountDarbius].value = res[StatNames.sd2CountDarbius];
    // }, err => {
    //   this.cardsData[StatNames.sd2CountDarbius].value = undefined;
    // });
  }

  classPicker(card: DashboardCard) {
    return card.value === undefined ? 'loading' : card.value ? 'red' : 'green';
  }

  openDialog(cardName: StatNames) {
    const card = this.cardsData[cardName];
    if (card.value) {
      const data = {extraValues: card.extraValues, title: card.title};
      this.dialog.open(card.extraComponent, {
        data,
        autoFocus: false,
        panelClass: 'selected-by-client-dialog'
      });
    }
  }

  private checkNotification() {
    if (this.notificationWeekdays.includes(moment().day())) {
      const dismissDate = localStorage.getItem('on-duty-notif-dismiss');
      if (dismissDate && dismissDate === moment().date().toString()) {
        this.toolbarMessage.setOnDutyNotification(false);
      } else {
        this.toolbarMessage.setOnDutyNotification(true);
      }

    }
  }
}

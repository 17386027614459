import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { OperationsTaskFieldsFragment } from '../../../generated/graphql';
import { TaskServiceService } from '../task-service.service';

@Component({
  selector: 'app-assign-tasks-users',
  templateUrl: './assign-tasks-users.component.html',
  styleUrls: ['./assign-tasks-users.component.scss']
})
export class AssignTasksUsersComponent {
  selectedUsers = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: OperationsTaskFieldsFragment[],
    private taskService: TaskServiceService,
    public dialog: MatDialogRef<AssignTasksUsersComponent>
  ) { }

  onSelectedProfilesChange(event) {
    this.selectedUsers = event;
  }

  onSubmit() {
    this.data.forEach(task => {
      const completedBy = task.completedBy.map(item => item.id);

      this.selectedUsers.forEach(id => {
        if (!completedBy.includes(id)) {
          completedBy.push(id);
        }
      });

      const taskUpdate = {
        taskId: task.id,
        id: task.id,
        order: task.order?.id,
        date: task.date,
        company: task.company?.id,
        category: task.category?.id,
        durationMinutes: task.durationMinutes,
        completedBy,
        status: task.status,
        comment: task.comment
      };

      this.taskService.updateTask(taskUpdate, ['GetOperationsTasks']).subscribe();
    });

    this.dialog.close();
  }
}

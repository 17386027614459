import { AfterViewInit, ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { CandidatesService } from '../candidates.service';
import { CandidatesCommentTableDatasource } from './candidates-comment-table-datasource';
import { RegionService } from '../../recruitment-dashboard/region.service';

@Component({
  selector: 'app-candidates-comment-table',
  templateUrl: './candidates-comment-table.component.html',
  styleUrls: ['./candidates-comment-table.component.scss']
})
export class CandidatesCommentTableComponent implements AfterViewInit {
  dataSource: CandidatesCommentTableDatasource;
  displayedColumns = ['candidate', 'status'];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: string },
    private cdr: ChangeDetectorRef,
    private dialogRef: MatDialogRef<CandidatesCommentTableComponent>,
    private candidatesService: CandidatesService,
    public regionService: RegionService
  ) { }

  ngAfterViewInit() {
    this.dataSource = new CandidatesCommentTableDatasource(this.candidatesService);
    this.dataSource.paginator = this.paginator;
    this.dataSource.filters.next({ id: this.data.id });
    this.cdr.detectChanges();
  }

  closeDialog() {
    this.dialogRef.close();
  }
}

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TaskSchedulingService } from './task-scheduling.service';
import { QualificationEnum } from '../../../generated/graphql';

@Component({
  selector: 'app-task-scheduling-dialog',
  templateUrl: './task-scheduling-dialog.component.html',
  styleUrls: ['./task-scheduling-dialog.component.scss'],
})
export class TaskSchedulingDialogComponent {
  scheduleForm: FormGroup;
  todayDate = new Date();

  constructor(
    public dialogRef: MatDialogRef<TaskSchedulingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      taskId: string,
      schedule: any,
      date: Date,
      isEditable: boolean
    },
    private fb: FormBuilder,
    private taskSchedulingService: TaskSchedulingService
  ) {
    this.scheduleForm = this.fb.group({
      endDate: [(data.schedule?.endDate || null), Validators.required],
      recurrencePeriod: [(data.schedule?.recurrencePeriod || null), Validators.required],
      intervalCount: [(data.schedule?.intervalCount || null), Validators.required],
      dayOfWeek: [data.schedule?.dayOfWeek || null],
      weekOfMonth: [data.schedule?.weekOfMonth || null],
      monthInterval: [data.schedule?.monthInterval || null],
      dayOfMonth: [data.schedule?.dayOfMonth || null]
    });
    if (!data.isEditable) {
      this.scheduleForm.disable();
    } else {
      this.checkMonthFields(data.schedule);
      this.scheduleForm.valueChanges.subscribe(value => this.checkMonthFields(value));
    }
  }

  checkMonthFields(value: any) {
    if (value?.recurrencePeriod === 'MONTHLY') {
      if (
        (typeof value?.weekOfMonth === 'number' || typeof value?.dayOfWeek === 'number')
        && this.scheduleForm.controls['dayOfMonth'].enabled
      ) {
        this.scheduleForm.controls['dayOfMonth'].disable();
      } else if (
        typeof value?.dayOfMonth === 'number' && this.scheduleForm.controls['weekOfMonth'].enabled
        && this.scheduleForm.controls['dayOfWeek'].enabled
      ) {
        this.scheduleForm.controls['weekOfMonth'].disable();
        this.scheduleForm.controls['dayOfWeek'].disable();
      } else if (
        typeof value?.weekOfMonth !== 'number' && typeof value?.dayOfWeek !== 'number'
        && this.scheduleForm.controls['dayOfMonth'].disabled
      ) {
        this.scheduleForm.controls['dayOfMonth'].enable();
      } else if (
        typeof value?.dayOfMonth !== 'number' && this.scheduleForm.controls['weekOfMonth'].disabled
        && this.scheduleForm.controls['dayOfWeek'].disabled
      ) {
        this.scheduleForm.controls['weekOfMonth'].enable();
        this.scheduleForm.controls['dayOfWeek'].enable();
      }
    }
  }

  recurrencePeriodChanged() {
    this.scheduleForm.controls['intervalCount'].setValue(null);
    this.scheduleForm.controls['dayOfWeek'].setValue(null);
    this.scheduleForm.controls['weekOfMonth'].setValue(null);
    this.scheduleForm.controls['monthInterval'].setValue(null);
    this.scheduleForm.controls['dayOfMonth'].setValue(null);
  }

  onSave() {
    if (this.scheduleForm.valid) {
      this.taskSchedulingService
        .saveSchedule(this.data.taskId, this.data.schedule?.id, this.scheduleForm.value)
        .subscribe((result) => {
          if (this.data.schedule) {
            this.dialogRef.close(result.data.updateTaskSchedule.schedule);
          } else {
            this.dialogRef.close(result.data.createTaskSchedule.schedule);
          }
        });
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  protected readonly qualificationEnum = QualificationEnum;
}

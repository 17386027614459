<div class="table-main-wrapper mat-elevation-z0">
  <div class="progress-bar-wrapper">
    <mat-progress-bar *ngIf="dataSource?.loading | async" [mode]="'indeterminate'"></mat-progress-bar>
  </div>

  <mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="row-select">
      <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
      <mat-cell *matCellDef="let task">
        <mat-checkbox
          [checked]="selectedRows.includes(task)"
          (change)='addToSelectedRows(task)'
          (click)='$event.stopPropagation()'
        ></mat-checkbox>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{'DATE' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let task">{{ task.date }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{'STATUS' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let task" (click)="$event.stopPropagation();">
        <!-- make task status pills as mat-menu trigger with statuses as menu items -->
        <app-task-status-pill
          [task]="task"
          [disableStatusChange]="isTaskStatusChangeDisabled(task)"
          [showNewTaskDate]="true"
          (statusChange)="onStatusChange($event, task)"
        ></app-task-status-pill>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="category">
      <mat-header-cell *matHeaderCellDef mat-sort-header="category__title">{{'CATEGORY' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let task">{{ task.category?.title }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef='country'>
      <mat-header-cell *matHeaderCellDef mat-sort-header='country__title'>{{'COUNTRY' | translate}}</mat-header-cell>
      <mat-cell *matCellDef='let task'>
        {{ task.countryIsoCode}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef >{{'DESCRIPTION' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let task" >
        <div [innerHTML]="task.comment | linkify" (click)="$event.stopImmediatePropagation()"></div>
        <span>{{task.candidate?.firstName}} {{task.candidate?.lastName}}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="employeeCalls">
      <mat-header-cell *matHeaderCellDef mat-sort-header="num_previous_tasks">{{ 'EMPLOYEE_CALLS' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let task">
        {{ task.numPreviousTasks }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="duration">
      <mat-header-cell *matHeaderCellDef mat-sort-header="duration_minutes">{{'DURATION' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let task">
        <app-editable-time-value [timeValue]="task.durationMinutes" (timeValueChange)="updateDuration(task, $event)"></app-editable-time-value>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="companyorder">
      <mat-header-cell *matHeaderCellDef >{{'COMPANY_N_ORDER' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let task">
        <div class="company-with-order">
          <span class='company'>{{task.company?.name || task.order?.company?.name}}</span>
          <span class="order">{{task.order?.specification?.positionTitle}}</span>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="owner">
      <mat-header-cell *matHeaderCellDef >{{'OWNER' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let task" (click)="$event.stopPropagation();">
        <app-popup-select-complete [selected]="task.completedBy"
                                 (changed)="onSelectedProfilesChange($event, task)">
        </app-popup-select-complete>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef >{{'ACTIONS' | translate}}</mat-header-cell>
      <mat-cell *matCellDef="let task" class='actions-container'>
        <!-- TODO show green icon if task is createdBySchedule. IN that case open read only schedule. Additionall add tooltips on different colors with explanations (translatable) -->
        <ng-container *ngIf="task.schedules.edges.length > 1; else singleSchedule">
          <button
            class='schedule-button'
            mat-icon-button
            [matMenuTriggerFor]="scheduleMenu"
            [ngClass]="{ 'green-icon': isScheduleIconGreen(task), 'red-icon': hasPastEndDate(task) }"
          >
            <mat-icon>schedule</mat-icon>
            <div *ngIf='getScheduleStatus(task)' class='task-status'>
              {{ getScheduleStatus(task) | translate }}
            </div>
          </button>
          <mat-menu #scheduleMenu="matMenu">
            <button
              mat-menu-item
              *ngFor="let edge of task.schedules.edges"
              (click)="openSchedulingDialog(task, edge.node)"
            >
              {{ edge.node.recurrencePeriod }}
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="openSchedulingDialog(task)">
              {{ 'TASK_SCHEDULING.CREATE_SCHEDULE' | translate }}
            </button>
          </mat-menu>
        </ng-container>

        <ng-template #singleSchedule>
          <button
            class='schedule-button'
            mat-icon-button
            (click)="$event.stopImmediatePropagation(); openSchedulingDialog(task, task.schedules.edges[0]?.node)"
            [ngClass]="{ 'green-icon': isScheduleIconGreen(task), 'red-icon': hasPastEndDate(task) }"
          >
            <mat-icon>schedule</mat-icon>
            <div *ngIf='getScheduleStatus(task)' class='task-status'>
              {{ getScheduleStatus(task) | translate }}
            </div>
          </button>
        </ng-template>
      </mat-cell>
    </ng-container>

    <!--mobile-->
    <ng-container matColumnDef="mobile">
      <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
      <mat-cell *matCellDef="let task" class='mobile-cell'>
        <div class='mobile-table'>
          <div class='mobile-column'>
            <div style='display: flex; flex-direction: column' class='mobile-cell'>
              <div class='mobile-header'>
                <span style='font-weight: bold'>{{'DATE' | translate}}</span>:
              </div>
              <div>{{ task.date }}</div>
            </div>

            <div style='display: flex; flex-direction: column' class='mobile-cell'>
              <div class='mobile-header'>
                <span style='font-weight: bold'>{{'STATUS' | translate}}</span>:
              </div>
              <div>
                <app-task-status-pill
                  [task]="task"
                  [disableStatusChange]="isTaskStatusChangeDisabled(task)"
                  [showNewTaskDate]="true"
                  (statusChange)="onStatusChange($event, task)"
                ></app-task-status-pill>
              </div>
            </div>

            <div style='display: flex; flex-direction: column' class='mobile-cell'>
              <div class='mobile-header'>
                <span style='font-weight: bold'>{{'CATEGORY' | translate}}</span>:
              </div>
              <div>
                {{ task.category?.title }}
              </div>
            </div>
          </div>

          <div class='mobile-column'>
            <div style='display: flex; flex-direction: column' class='mobile-cell'>
              <div class='mobile-header'>
                <span style='font-weight: bold'>{{'COUNTRY' | translate}}</span>:
              </div>
              <div>
                {{ task.countryIsoCode}}
              </div>
            </div>

            <div *ngIf='task.comment' style='display: flex; flex-direction: column' class='mobile-cell'>
              <div class='mobile-header'>
                <span style='font-weight: bold'>{{'DESCRIPTION' | translate}}</span>:
              </div>
              <div>
                <div [innerHTML]="task.comment | linkify" (click)="$event.stopImmediatePropagation()"></div>
                <span>{{task.candidate?.firstName}} {{task.candidate?.lastName}}</span>
              </div>
            </div>

            <div style='display: flex; flex-direction: column' class='mobile-cell'>
              <div class='mobile-header'>
                <span style='font-weight: bold'>{{ 'EMPLOYEE_CALLS' | translate }}</span>:
              </div>
              <div>
                {{ task.numPreviousTasks }}
              </div>
            </div>
          </div>

          <div class='mobile-column'>
            <div style='display: flex; flex-direction: column' class='mobile-cell'>
              <div class='mobile-header'>
                <span style='font-weight: bold'>{{'DURATION' | translate}}</span>:
              </div>
              <div>
                <app-editable-time-value
                  [timeValue]="task.durationMinutes"
                  (timeValueChange)="updateDuration(task, $event)">
                </app-editable-time-value>
              </div>
            </div>

            <div style='display: flex; flex-direction: column' class="company-with-order mobile-cell">
              <div class='mobile-header'>
                <span style='font-weight: bold'>{{'COMPANY_N_ORDER' | translate}}</span>:
              </div>
              <div>
                <span class='company'>{{task.company?.name || task.order?.company?.name}}</span>
                <span class="order">{{task.order?.specification?.positionTitle}}</span>
              </div>
            </div>

            <div style='display: flex; flex-direction: column' class='mobile-cell'>
              <div class='mobile-header'>
                <span style='font-weight: bold'>{{'ACTIONS' | translate}}</span>:
              </div>

              <div>
                <ng-container *ngIf="task.schedules.edges.length > 1; else singleSchedule">
                  <button
                    class='schedule-button'
                    mat-icon-button
                    [matMenuTriggerFor]="scheduleMenu"
                    [ngClass]="{ 'green-icon': isScheduleIconGreen(task), 'red-icon': hasPastEndDate(task) }"
                  >
                    <mat-icon>schedule</mat-icon>
                    <div *ngIf='getScheduleStatus(task)' class='task-status'>
                      {{ getScheduleStatus(task) | translate }}
                    </div>
                  </button>

                  <mat-menu #scheduleMenu="matMenu">
                    <button
                      mat-menu-item
                      *ngFor="let edge of task.schedules.edges"
                      (click)="openSchedulingDialog(task, edge.node)"
                    >
                      {{ edge.node.recurrencePeriod }}
                    </button>
                    <mat-divider></mat-divider>
                    <button mat-menu-item (click)="openSchedulingDialog(task)">
                      {{ 'TASK_SCHEDULING.CREATE_SCHEDULE' | translate }}
                    </button>
                  </mat-menu>
                </ng-container>

                <ng-template #singleSchedule>
                  <button
                    class='schedule-button'
                    mat-icon-button
                    (click)="$event.stopImmediatePropagation(); openSchedulingDialog(task, task.schedules.edges[0]?.node)"
                    [ngClass]="{ 'green-icon': isScheduleIconGreen(task), 'red-icon': hasPastEndDate(task) }"
                  >
                    <mat-icon>schedule</mat-icon>
                    <div *ngIf='getScheduleStatus(task)' class='task-status'>
                    </div>
                  </button>
                </ng-template>
              </div>
            </div>
          </div>

          <div class='mobile-column single'>
            <div class='mobile-header'>
              <span style='font-weight: bold'>{{'OWNER' | translate}}</span>:
            </div>
            <div>
              <app-popup-select-complete
                [selected]="task.completedBy"
                (changed)="onSelectedProfilesChange($event, task)"
              ></app-popup-select-complete>
            </div>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns" [ngClass]="{'noneClass': resolution < 768}"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="select(row)" [ngClass]="{'mobile-first-row': resolution < 768}"></mat-row>
  </mat-table>

  <mat-paginator
    #paginator
    [length]="dataSource?.totalCount"
    [pageIndex]="0"
    [pageSize]="50"
    [pageSizeOptions]="[10, 25, 50, 100]"
  ></mat-paginator>
</div>

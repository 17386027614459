<!-- metric-table.component.html -->
<div class='metric-table-container'>
  <div fxLayout fxLayoutAlign='space-between'>
    <div fxLayout fxLayoutAlign='start center' fxLayoutGap='48px'>
      <div class='table-title'>{{ cardTitle || ('METRICS_TABLE' | translate) }}</div>

      <app-filter-bar
        *ngIf='initDates'
        [filters]="['dateRange']"
        [initialValue]='initDates'
        [smallNewDateRange]="true"
        [preventFiltersStore]="true"
        (filterChanges)="onFilterChanges($event)"
      ></app-filter-bar>

      <mat-form-field class="barchart-goal-input">
        <mat-label>{{'GOAL' | translate }}</mat-label>
        <input [(ngModel)]="barchartGoal" (input)="generateWeeklySummary(sortItemsCatch)" matInput>
      </mat-form-field>

      <button
        mat-icon-button
        class="barchart-goal-btn"
        [disabled]="barchartGoal === barchartGoalCatch"
        (click)="saveBarchartGoal()"
      >
        <mat-icon>check</mat-icon>
      </button>

      <button
        mat-icon-button
        class="barchart-goal-btn"
        [disabled]="barchartGoal === barchartGoalCatch"
        (click)="barchartGoal = barchartGoalCatch; generateWeeklySummary(sortItemsCatch)"
      >
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </div>

  <div class="progress-bar-wrapper">
    <mat-progress-bar *ngIf='isLoading' [mode]="'indeterminate'"></mat-progress-bar>
  </div>

  <canvas
    *ngIf="chart"
    baseChart
    chartType="bar"
    [datasets]="chart.datasets"
    [labels]="chart.labels"
    [options]="chart.options"
    [plugins]="pieChartPlugins"
    legend="true">
  </canvas>
</div>

import { ChangeDetectorRef, EventEmitter, Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  onLanguageChange = new EventEmitter<string>();

  constructor(
    private translateService: TranslateService,
    private dateAdapter: DateAdapter<any>
    // private cdr: ChangeDetectorRef
  ) { }

  setLanguage(lang: string): void {
    localStorage.setItem('lang', lang);
    this.translateService.use(lang);
    this.dateAdapter.setLocale(lang);
    this.onLanguageChange.emit(lang);
  }

  getLanguage(): string  {
    return localStorage.getItem('lang') || 'en'; //JSON.parse(sessionStorage.getItem('lang'));
  }

}

<div ngFileDrop class="component-wrapper" (uploadOutput)="searchForm?.valid ? onFileChange($event) : null">
  <div class="search-fields" [formGroup]="searchForm" fxLayout="row" fxLayoutGap="12px">
    <app-company-selector fxFlex="400px" (companyChange)="onCompanyChange($event)" #companySelector></app-company-selector>

    <mat-form-field appearance="outline" fxFlex="200px">
      <mat-label>Month and Year</mat-label>
      <input matInput [matDatepicker]="datepicker" formControlName="date">
      <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
      <mat-datepicker
        panelClass="example-month-picker"
        startView="multi-year"
        (monthSelected)="setMonthAndYear($event, datepicker)"
        #datepicker
      ></mat-datepicker>
    </mat-form-field>

    <button
      mat-stroked-button
      (click)="datepicker.select(moment().add(-1, 'month'));
               saveConfigToLS('yearMonth', moment().add(-1, 'month').format('YYYY-MM'))"
    >
      {{ 'COMMON.PREVIOUS_MONTH' | translate }}
    </button>

    <button
      mat-stroked-button
      (click)="datepicker.select(moment());
               saveConfigToLS('yearMonth', moment().format('YYYY-MM'))"
    >
      {{ 'COMMON.THIS_MONTH' | translate }}
    </button>
  </div>

  <div class="action-row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
    <mat-slide-toggle *ngIf="isEmployeeTableReady" class="hide-build-table" [(ngModel)]="hideBuildAndUnselected">
      {{ 'EXCEL_WIDGET.HIDE_BUILD_OPTIONS' | translate }}
    </mat-slide-toggle>

    <button *ngIf="displayedColumns.length" mat-stroked-button (click)="resetAll(true)">
      {{ 'COMMON.RESET_ALL' | translate }}
    </button>
  </div>

  <div *ngIf="!dataSource && searchForm?.valid" class="upload-container" (click)="fileInput.click()">
    <div [matMenuTriggerFor]="sheetOptions" #sheetOptionsMenuTrigger="matMenuTrigger"></div>
    <mat-icon svgIcon="upload"></mat-icon>
    <span class="text">{{ 'DRAG_AND_DROP_OR' | translate }} <a>{{ 'UPLOAD_FILE' | translate }}</a></span>
    <input ngFileSelect type="file" (uploadOutput)="onFileChange($event)" #fileInput style="display: none"/>
  </div>

  <div *ngIf="isEmployeeTableReady && encodedTypesDataSource?.data.length" class="encoded-types-table-wrapper">
    <h3 *ngIf="displayedColumns.length">Found these unique values</h3>

    <cdk-virtual-scroll-viewport *ngIf="isEncodedTypeReady" tvsItemSize="36" headerHeight="36" style="height: 250px; width: 1080px;">
      <mat-table [dataSource]="encodedTypesDataSource" matSort>
        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Type' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="noShow">
          <mat-header-cell *matHeaderCellDef>{{ 'No show' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-checkbox [formControl]="getDecodeControlByGroupAndKeyGeneral(element, 'noShow')"></mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="workFrom">
          <mat-header-cell *matHeaderCellDef>{{ 'Work from' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-form-field [style.display]="getDecodeBlockVisibility(element) === '0' ? 'flex' : 'none'">
              <input matInput placeholder="00:00" [formControl]="getDecodeControlByGroupAndKeyForMode(element, 'workFrom', 'fromTo')">
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="workTo">
          <mat-header-cell *matHeaderCellDef>{{ 'Work to' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-form-field [style.display]="getDecodeBlockVisibility(element) === '0' ? 'flex' : 'none'">
              <input matInput placeholder="00:00" [formControl]="getDecodeControlByGroupAndKeyForMode(element, 'workTo', 'fromTo')">
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lunchFrom">
          <mat-header-cell *matHeaderCellDef>{{ 'Lunch from' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-form-field [style.display]="getDecodeBlockVisibility(element) === '0' ? 'flex' : 'none'">
              <input matInput placeholder="00:00" [formControl]="getDecodeControlByGroupAndKeyForMode(element, 'lunchFrom', 'fromTo')">
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lunchTo">
          <mat-header-cell *matHeaderCellDef>{{ 'Lunch to' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-form-field [style.display]="getDecodeBlockVisibility(element) === '0' ? 'flex' : 'none'">
              <input matInput placeholder="00:00" [formControl]="getDecodeControlByGroupAndKeyForMode(element, 'lunchTo', 'fromTo')">
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="hrsDay">
          <mat-header-cell *matHeaderCellDef>{{ 'Hrs day' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-form-field [style.display]="getDecodeBlockVisibility(element) === '1' ? 'flex' : 'none'">
              <input matInput placeholder="0" [formControl]="getDecodeControlByGroupAndKeyForMode(element, 'hrsDay', 'hoursSum')">
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="hrsNight">
          <mat-header-cell *matHeaderCellDef>{{ 'Hrs night' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-form-field [style.display]="getDecodeBlockVisibility(element) === '1' ? 'flex' : 'none'">
              <input matInput placeholder="0" [formControl]="getDecodeControlByGroupAndKeyForMode(element, 'hrsNight', 'hoursSum')">
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="overtimeDay">
          <mat-header-cell *matHeaderCellDef>{{ 'Overtime day' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-form-field [style.display]="getDecodeBlockVisibility(element) === '1' ? 'flex' : 'none'">
              <input matInput placeholder="0" [formControl]="getDecodeControlByGroupAndKeyForMode(element, 'overtimeDay', 'hoursSum')">
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="overtimeNight">
          <mat-header-cell *matHeaderCellDef>{{ 'Overtime night' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-form-field [style.display]="getDecodeBlockVisibility(element) === '1' ? 'flex' : 'none'">
              <input matInput placeholder="0" [formControl]="getDecodeControlByGroupAndKeyForMode(element, 'overtimeNight', 'hoursSum')">
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="holidaysDay">
          <mat-header-cell *matHeaderCellDef>{{ 'Holidays day' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-form-field [style.display]="getDecodeBlockVisibility(element) === '1' ? 'flex' : 'none'">
              <input matInput placeholder="0" [formControl]="getDecodeControlByGroupAndKeyForMode(element, 'holidaysDay', 'hoursSum')">
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="holidaysNight">
          <mat-header-cell *matHeaderCellDef>{{ 'Holidays night' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-form-field [style.display]="getDecodeBlockVisibility(element) === '1' ? 'flex' : 'none'">
              <input matInput placeholder="0" [formControl]="getDecodeControlByGroupAndKeyForMode(element, 'holidaysNight', 'hoursSum')">
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="encodedTypesDisplayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: encodedTypesDisplayedColumns"></mat-row>
      </mat-table>
    </cdk-virtual-scroll-viewport>
  </div>

  <h3 *ngIf="displayedColumns.length && !hideBuildAndUnselected" [style.margin-left]="'16px'">
    {{ 'EXCEL_WIDGET.DATA_SELECT_MESSAGE' | translate }}
  </h3>

  <div *ngIf="displayedColumns.length && !hideBuildAndUnselected" class="selection-row" fxLayout="row">
    <div
      *ngFor="let selection of selectionPointsTitles"
      class="selection-item"
      fxLayout="column"
      fxLayoutAlign="center center"
      [class.selected]="selection === 'addDay' ? selectionPoints[selection]?.valueExColIdxs?.length : selectionPoints[selection]?.valueExColIdx"
    >
      <div>{{ selectionPoints[selection]?.title }}</div>
      <mat-icon>{{ selection === 'addDay' ? 'calendar_today' : selectionPoints[selection]?.valueEx ? 'check' : 'hourglass_empty' }}</mat-icon>
      <mat-icon
        *ngIf="selection === 'addDay' ? selectionPoints[selection]?.valueExColIdxs?.length : selectionPoints[selection]?.valueExColIdx"
        class="column-cancel-icon"
        (click)="deselectColumn(selection)"
      >cancel</mat-icon>
    </div>

    <button mat-stroked-button [disabled]="!isBuildButtonAvailable()" (click)="generateComparingTable(true)">
      {{ (isEmployeeTableReady ? 'RELOAD_DATA' : 'EXCEL_WIDGET.LOAD_DATA_FOR_COMPARING') | translate }}
    </button>
  </div>

  <div class="excel-table-container">
    <mat-spinner *ngIf="isMainDataLoading" diameter="240"></mat-spinner>

    <mat-table *ngIf="!isMainDataLoading && displayedColumns.length" [dataSource]="dataSource">
      <ng-container *ngFor="let col of displayedColumns; let i = index" [matColumnDef]="col" [sticky]="i === 0">
        <mat-header-cell
          *matHeaderCellDef
          [style.min-width]="i === 0 ? '52px' : isEmployeeNameColumn(i) ? '150px' : '100px'"
          [style.max-width]="i === 0 ? '52px' : 'unset'"
          [style.flex-direction]="'column'"
          [style.justify-content]="'center'"
          [class.selected]="isColumnSelected(i)"
          [class.hover]="i !== 0 && currentColHovered === i"
          [class.hidden-column]="(hideBuildAndUnselected && hiddenColumnsIdx.includes(i))
                              || (col === 'fetchedId_key' && selectionPoints.employeeId.valueExColIdx !== 1)
                              || (col === 'days_worked' && !isAbsencesMatrixReady) || (col === 'days_absence' && !isAbsencesMatrixReady)"
          [class.clickable]="i !== 0 && !hideBuildAndUnselected && headerRowIdx >= 0"
          [class.left-delimiter]="selectionPoints.addDay.valueExColIdxs[0] === i"
          [matMenuTriggerFor]="i !== 0 && !hideBuildAndUnselected && headerRowIdx >= 0 ? selectOptions : undefined"
          (mouseenter)="currentColHovered = i"
          (mouseleave)="currentColHovered = undefined"
          (click)="processedColumn = i"
        >
          <span>{{ i >= 1 ? 'C ' + (i - 1) : '' }}</span>
          <span>{{ getColumnNameByIdx(i) }}</span>
        </mat-header-cell>

        <mat-cell
          *matCellDef="let element; let j = index"
          [style.min-width]="i === 0 ? '52px' : isEmployeeNameColumn(i) ? '150px' : '78px'"
          [style.max-width]="i === 0 ? '52px' : 'unset'"
          [class.first]="!hideBuildAndUnselected && i === 0"
          [class.selected]="(!hideBuildAndUnselected && isColumnSelected(i)) || (paginator.pageIndex === 0 && headerRowIdx === j)"
          [class.hover]="(i !== 0 && currentColHovered === i) || currentRowHovered === j"
          [class.excluded]="excludeRowsSelection.isSelected(j)"
          [class.error]="isEmployeeNameOrIdError(i, j)"
          [class.match]="getDayValueMatch(i, j, element[col], getDecodeBlockVisibility(element[col])) || getTotalStatValueMatch(i, j, element[col], getDecodeBlockVisibility(element[col]))"
          [class.unmatch]="getDayValueUnmatch(i, j, element[col], getDecodeBlockVisibility(element[col])) || getTotalStatValueUnmatch(i, j, element[col], getDecodeBlockVisibility(element[col]))"
          [class.warning]="getDayValueWarn(i, j, element[col]) || getTotalStatValueWarn(i, j, element[col])"
          [class.hidden-column]="(hideBuildAndUnselected && hiddenColumnsIdx.includes(i))
                              || (col === 'fetchedId_key' && selectionPoints.employeeId.valueExColIdx !== 1)
                              || (col === 'days_worked' && !isAbsencesMatrixReady) || (col === 'days_absence' && !isAbsencesMatrixReady)"
          [class.clickable-cell]="(paginator.pageIndex === 0 ? j > headerRowIdx && i === 0 : i === 0) || (isDayCompareDisplay(i, j) && getEmployeeShiftData(i, j))"
          [class.left-delimiter]="selectionPoints.addDay.valueExColIdxs[0] === i"
          [class.selection-process]="selectionRow === j && selectedCells.includes(i)"
          [class.user-selectable]="!isEmployeeTableReady || isEmployeeIdColumn(i) || this.isEmployeeNameColumn(i)"
          [class.locked]="isEmployeeLocked(i, j)"
          [class.selected-row]="isSelectedForMulti(j)"
          [matMenuTriggerFor]="(paginator.pageIndex === 0 ? j > headerRowIdx && i === 0 : i === 0) ? selectRowAction : undefined"
          (mousedown)="isEmployeeTableReady && i >= selectionPoints.addDay.valueExColIdxs[0] ? onMouseDown($event, i, j) : null"
          (mouseover)="onMouseOver($event, i)"
          (mouseup)="onMouseUp()"
          (mouseenter)="i === 0 ? currentRowHovered = j : null"
          (mouseleave)="i === 0 ? currentRowHovered = undefined : null"
          (click)="i === 0 && !hideBuildAndUnselected && (paginator.pageIndex === 0 && headerRowIdx === undefined) ? selectHeaderRow(j, element) : null;
                   isDayCompareDisplay(i, j) && unblockClickAction ? openShiftDialog(getEmployeeShiftData(i, j)?.shift_status, getEmployeeShiftData(i, j)?.shift_id, getEmployeeIdByRowIdx(j), element[col]) : null;
                   (paginator.pageIndex === 0 ? j > headerRowIdx && i === 0 : i === 0) ? processedRow = j : null"
        >
          <div *ngIf="(!isDayCompareDisplay(i, j) && !isSelectedColumn(i)) || ((isEmployeeIdColumn(i) || isEmployeeNameColumn(i)) && (paginator.pageIndex === 0 && j <= headerRowIdx))">
            <span *ngIf="!staticColumns.includes(col)">{{ formatToDecimal(element[col], 4) }}</span>
            <span *ngIf="col === 'numeric_key'">
              <div style='display: flex; flex-direction: row;'>
                <div>
                  {{ paginator.pageIndex === 0 && j === headerRowIdx ? 'Head' : 'R ' + (j + paginator.pageIndex * paginator.pageSize) }}
                </div>
                <div style='margin-left: 10px' *ngIf='!(paginator.pageIndex === 0 && j === headerRowIdx) && hideBuildAndUnselected'>
                  <mat-checkbox
                    class='selected-rows-checkboxes'
                    [checked]="selectedRows.includes(j)"
                    [disabled]="isEmployeeNameOrIdError(i + 1, j)"
                    (change)='addToSelectedRows(j)'
                    (click)='$event.stopPropagation()'
                  ></mat-checkbox>
                </div>
                <div style='margin-left: 4px' *ngIf='(paginator.pageIndex === 0 && j === headerRowIdx) && hideBuildAndUnselected'>
                  <mat-checkbox
                    class='selected-rows-checkboxes'
                    [checked]="availableToSelectLength === selectedRows.length && selectedRows.length > 0"
                    [indeterminate]="selectedRows.length > 0 && selectedRows.length < availableToSelectLength"
                    (change)='toggleAllRowsSelect($event, i)'
                    (click)='$event.stopPropagation()'
                  ></mat-checkbox>
                </div>
              </div>
            </span>
            <span *ngIf="col === 'fetchedId_key' && paginator.pageIndex === 0 && j === headerRowIdx">{{ 'Livas ID' }}</span>
            <span *ngIf="col === 'days_worked' && paginator.pageIndex === 0 && j === headerRowIdx">{{ 'Days worked' }}</span>
            <span *ngIf="col === 'days_absence' && paginator.pageIndex === 0 && j === headerRowIdx">{{ 'Absence days' }}</span>
          </div>

          <div *ngIf="col === 'days_worked' && (paginator.pageIndex > 0 || j > headerRowIdx)">
            {{ this.workedAbsenceDaysData[getEmployeeIdByRowIdx(j)]?.workedDay || 0 }}
          </div>

          <div *ngIf="col === 'days_absence' && (paginator.pageIndex > 0 || j > headerRowIdx)">
            {{ this.workedAbsenceDaysData[getEmployeeIdByRowIdx(j)]?.absenceDays || 0 }}
          </div>

          <div *ngIf="isEmployeeIdColumn(i) && (paginator.pageIndex > 0 || j > headerRowIdx)">
            {{ getEmployeeIdByRowIdx(j) }}
          </div>

          <div *ngIf="isEmployeeNameColumn(i) && (paginator.pageIndex > 0 || j > headerRowIdx)">
            {{ getEmployeeNameByRowIdx(j) }}
          </div>

          <div
            *ngIf="isColumRelatedToOneOfSelections(secondSelectionPoints, i)"
            [style.width]="'100%'"
          >
            <span *ngIf="j <= headerRowIdx || !isFullDimeDataLoaded">{{ formatToDecimal(element[col], 4) }}</span>

            <div
              *ngIf="(paginator.pageIndex > 0 || j > headerRowIdx) && isEmployeeTableReady && isFullDimeDataLoaded"
              class="numeric-block"
              fxLayout="column"
              fxLayoutAlign="center center"
              fxLayoutGap="4px"
              fxFlex="100"
            >
              <div *ngIf="element[col] || element[col] === 0; else genericWarIcon">{{ formatToDecimal(element[col], 4) }}</div>
              <div *ngIf="totalValuesByEmployee[getEmployeeIdByRowIdx(j)]?.[getColumnValueKeyByColIdx(i)]
                          || totalValuesByEmployee[getEmployeeIdByRowIdx(j)]?.[getColumnValueKeyByColIdx(i)] === 0; else genericWarIcon"
              >
                {{ formatToDecimal(totalValuesByEmployee[getEmployeeIdByRowIdx(j)]?.[getColumnValueKeyByColIdx(i)], 4) }}
              </div>
            </div>
          </div>

          <div
            *ngIf="isDayCompareDisplay(i, j) && (element[col] || (getEmployeeShiftData(i, j)?.totalWorked || getEmployeeShiftData(i, j)?.totalWorked === 0))"
            class="numeric-block"
            fxLayout="column"
            fxLayoutAlign="center center"
            fxLayoutGap="4px"
            fxFlex="100"
          >
            <div *ngIf="dayValueDisplayFn(element[col]) || element[col]; else genericWarIcon" matTooltip="Excel value">
              {{ formatToDecimal(dayValueDisplayFn(element[col]) || element[col]) }}
            </div>

            <div *ngIf="getEmployeeShiftData(i, j)?.totalWorked || getEmployeeShiftData(i, j)?.totalWorked === 0; else genericWarIcon" class="worked-indicator">
              <span matTooltip='Absence unpaid'>{{ formatToDecimal(absencesMatrix[getEmployeeIdByRowIdx(j)]?.[dataSource.fullData[headerRowIdx]?.[col]]?.absenceUnpaid) || '-' }}</span>
              | <span matTooltip='Total planned hours'>{{ formatToDecimal(getEmployeeShiftData(i, j)?.total_planned_hours) || '-' }}</span>
              | <span matTooltip='Total worked (LIVAS)'>{{ formatToDecimal(getEmployeeShiftData(i, j)?.totalWorked) }}</span>
              <mat-icon
                *ngIf="formatToDecimal(absencesMatrix[getEmployeeIdByRowIdx(j)]?.[dataSource.fullData[headerRowIdx]?.[col]]?.absenceUnpaid) === '0'"
                matTooltip='Absence paid'
                svgIcon="currency-eur"
              ></mat-icon>
            </div>
          </div>

          <div
            *ngIf="isDayCompareDisplay(i, j) && !(element[col] || (getEmployeeShiftData(i, j)?.totalWorked || getEmployeeShiftData(i, j)?.totalWorked === 0))"
            class="numeric-block"
            fxLayout="column"
            fxLayoutAlign="center center"
            fxLayoutGap="4px"
            fxFlex="100"
          >-</div>

          <div *ngIf="isEmployeeNameOrIdError(i, j)" class="empty-id-input">
            <input
              matInput
              [placeholder]="isEmployeeIdColumn(i) ? 'Bad ID' : 'Bad Name'"
              [value]="element[col] || ''"
              (blur)="element[col] = $event.target.value; setNoFoundEmployeeId($event, i, j)"
              (keyup.enter)="element[col] = $event.target.value; setNoFoundEmployeeId($event, i, j)"
            >
          </div>

          <div class="action-icons" fxLayout="column" fxLayoutAlign="center center">
            <mat-icon
              *ngIf="!hideBuildAndUnselected && i === 0 && (paginator.pageIndex > 0 || j > headerRowIdx)"
              [class.add]="excludeRowsSelection.isSelected(j)"
              [class.disabled]="(selectionPoints.employeeId.valueExColIdx && !getEmployeeIdByRowIdx(j)) || (selectionPoints.nameColumn.valueExColIdx && !getEmployeeNameByRowIdx(j))"
              (click)="$event.stopPropagation(); !rowActionIcon._elementRef.nativeElement.classList.contains('disabled') ? excludeRowsSelection.toggle(j) : null"
              #rowActionIcon
            >
              {{ excludeRowsSelection.isSelected(j) ? 'add_circle_outline' : 'delete_forever' }}
            </mat-icon>

            <mat-icon
              *ngIf="!hideBuildAndUnselected && i === 0 && (paginator.pageIndex === 0 && j === headerRowIdx)"
              class="autorenew"
              (click)="$event.stopPropagation(); resetAll()"
            >autorenew</mat-icon>

            <mat-icon
              *ngIf="isEmployeeTableReady && isEmployeeNameColumn(i) && (paginator.pageIndex > 0 || j > headerRowIdx)
                     && !isEmployeeNameOrIdError(selectionPoints.employeeId.valueExColIdx, j)"
              class="profile-info"
              (click)="openEmployeeCard(getEmployeeIdByRowIdx(j))"
            >open_in_new</mat-icon>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
        [class.hidden]="hideBuildAndUnselected"
      ></mat-header-row>

      <mat-row
        *matRowDef="let row; let j = index; columns: displayedColumns"
        [class.hidden]="hideBuildAndUnselected && ((paginator.pageIndex === 0 && j < headerRowIdx) || excludeRowsSelection.isSelected(j))"
      ></mat-row>
    </mat-table>
  </div>

  <mat-paginator [class.hidden]="!dataSource || isMainDataLoading" [pageSize]="25"></mat-paginator>
</div>

<ng-template #genericWarIcon>
  <div fxFlex="100" fxLayout="row" fxLayoutAlign="center center">
    <mat-icon class="warning-icon">warning</mat-icon>
  </div>
</ng-template>

<mat-menu #selectOptions="matMenu" class="select-column-role">
  <div class="title">{{ 'EXCEL_WIDGET.USE_COLUMN_AS' | translate }}</div>

  <div
    *ngFor="let selection of selectionPointsTitles"
    class="menu-item"
    [class.selected]="selection === 'addDay' ? selectionPoints[selection]?.valueExColIdxs?.length : selectionPoints[selection]?.valueExColIdx"
    (click)="selectionInProgress = selection; selectColumn(processedColumn)"
  >
    <div>{{ selectionPoints[selection]?.title }}</div>
  </div>
</mat-menu>

<mat-menu #selectRowAction="matMenu" class="select-action-menu">
  <div class="title">Actions with row</div>

  <button
    *ngIf='selectedRows.length === 0'
    mat-menu-item
    [disabled]="!shiftsData.length"
    (click)="updateSingleRowData(getEmployeeIdByRowIdx(processedRow))"
  >
    Refresh row data
  </button>

  <button
    *ngIf='selectedRows.length === 0'
    mat-menu-item
    [disabled]="!shiftsData.length"
    (click)="addMissingShifts(selectedCells?.length && selectionRow === processedRow)"
  >
    {{ 'Create missing shifts in LIVAS based on Excel' + (selectedCells?.length && selectionRow === processedRow ? ' (selected cells)' : ' (full row)') }}
  </button>

  <button
    *ngIf='selectedRows.length > 0'
    mat-menu-item
    [disabled]="!shiftsData.length"
    (click)="addMissingShiftsFromExcelForMultipleRows()"
  >
    {{'Create missing shifts in LIVAS based on Excel (selected rows)'}}
  </button>

  <button
    *ngIf='selectedRows.length === 0'
    mat-menu-item
    [disabled]="!shiftsData.length"
    (click)="updateSelectedShiftsFromExcel(selectedCells?.length && selectionRow === processedRow)"
  >
    {{ 'Update from Excel'  + (selectedCells?.length && selectionRow === processedRow ? ' (selected cells)' : ' (full row)') }}
  </button>

  <button
    *ngIf='selectedRows.length > 0'
    mat-menu-item
    [disabled]="!shiftsData.length"
    (click)="updateSelectedShiftsFromExcelForMultipleRows()"
  >
    {{ 'Update from Excel (selected rows)'}}
  </button>

  <button
    *ngIf='selectedRows.length === 0'
    mat-menu-item
    [disabled]="!shiftsData.length"
    (click)="confirmFactForSelectedShifts(selectedCells?.length && selectionRow === processedRow)"
  >
    {{ 'Confirm Fact'  + (selectedCells?.length && selectionRow === processedRow ? ' (selected cells)' : ' (full row)') }}
  </button>

  <button
    *ngIf='selectedRows.length > 0'
    mat-menu-item
    [disabled]="!shiftsData.length"
    (click)="confirmFactForShiftsFromExcelForMultipleRows"
  >
    {{ 'Confirm Fact (selected rows)'}}
  </button>
</mat-menu>

<mat-menu #sheetOptions="matMenu" class="select-sheet-menu">
  <div class="title">{{ 'EXCEL_WIDGET.SELECT_SHEET_TO_WORK' | translate }}</div>
  <div *ngFor="let sheet of sheetNames" class="menu-item" (click)="proceedWorkWithExcel(sheetsData[sheet])">
    <div>{{ sheet }}</div>
  </div>
</mat-menu>

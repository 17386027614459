<div class='wrapper'>
  <div class='links-container'>
    <ng-container *ngFor='let link of (links.length > 6 ? links.slice(0, 6) : links)'>
      <a class='ext-link' target="_blank" [href]='link.url'>
        <span class='link-text'>{{ link.title }}</span>
      </a>
    </ng-container>
  </div>
  <div class='add-btn' *ngIf='links.length > 6' [matMenuTriggerFor]="menu">
    <mat-icon>arrow_drop_down</mat-icon>
  </div>
  <mat-menu #menu="matMenu">
    <ng-container *ngFor='let link of links.slice(6)'>
      <a target="_blank" [href]='link.url' mat-menu-item>{{ link.title }}</a>
    </ng-container>
  </mat-menu>
  <div class='add-btn' (click)='openCreationDialog()'>
    <mat-icon>add</mat-icon>
  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment/moment';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseChartDirective } from 'ng2-charts/lib/base-chart.directive';
import DatalabelsPlugin from 'chartjs-plugin-datalabels';

import { StandupDashboardServiceService } from '../standup-dashboard-service.service';
import { DashboardType, MetricGroupAssignmentType, MetricValueType } from '../../../../generated/graphql';
import { FilterInterface } from '../../../shared/filter-bar/filter-bar.component';
import { RelayIdService } from '../../../shared/relay-id.service';
import { OrderTypeEnum } from '../../../shared/types/order-types';
import { LanguageService } from '../../../navigation/language.service';

@Component({
  selector: 'app-weekly-barchart-card',
  templateUrl: './weekly-barchart-card.component.html',
  styleUrls: ['./weekly-barchart-card.component.scss']
})
export class WeeklyBarchartCardComponent implements OnInit {
  Object = Object;
  moment = moment;
  initDates;
  filterCache: FilterInterface;
  isDatePickerClosed: boolean;
  isLoading = true;
  barchartGoalCatch: number;
  sortItemsCatch: any[];
  title: string;

  chart: BaseChartDirective;
  pieChartPlugins = [DatalabelsPlugin];

  @Input() cardTitle: string;
  @Input() dashboardItem: DashboardType;
  @Input() barchartGoal: number;
  @Input() barchartCardId: string;
  @Input() cardId: any;

  constructor(
    private dashboardService: StandupDashboardServiceService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private languageService: LanguageService,
    public relayIdService: RelayIdService
  ) { }

  ngOnInit() {
    this.barchartGoalCatch = this.barchartGoal;
    const currentWeekSunday = this.getCurrentWeekSunday();
    const mondayNWeeksAgo = this.getMondayNWeeksAgo(10);
    this.initDates = {
      dateRange: {
        dateFrom: mondayNWeeksAgo,
        dateTo: currentWeekSunday
      }
    };
    this.route.queryParams.subscribe(params => {
      const paramDateFrom = params['dateFromWi'];
      const paramDateTo = params['dateToWi'];
      if (paramDateFrom && paramDateTo) {
        this.initDates = {
          dateRange: {
            dateFrom: moment(paramDateFrom),
            dateTo: moment(paramDateTo)
          }
        };
      }
    });
    this.languageService.onLanguageChange.subscribe(() => {
      if (this.sortItemsCatch) {
        this.generateWeeklySummary(this.sortItemsCatch);
      }
    });
  }

  getCurrentWeekSunday() {
    const today = moment();
    const dayOfWeek = today.isoWeekday();
    const daysUntilSunday = 7 - dayOfWeek;

    return today.clone().add(daysUntilSunday, 'days');
  }

  getMondayNWeeksAgo(weeksAgo: number) {
    const today = moment();
    const currentMonday = today.clone().startOf('isoWeek');
    const mondayNWeeksAgo = currentMonday.clone().subtract(weeksAgo, 'weeks');

    return mondayNWeeksAgo;
  }

  onFilterChanges(filters: FilterInterface, force?: boolean) {
    const dateRange = filters.dateRange;
    const cachedRange = this.filterCache?.dateRange;

    this.isDatePickerClosed = !!(
      !this.filterCache || (dateRange.dateFrom.isSame(cachedRange.dateFrom) && (!cachedRange.dateTo && dateRange.dateTo))
    );

    this.filterCache = filters;

    if ((this.isDatePickerClosed || force) && dateRange && (dateRange.dateFrom && dateRange.dateTo)) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          dateFromWi: dateRange.dateFrom ? moment(dateRange.dateFrom).format('YYYY-MM-DD') : null,
          dateToWi: dateRange.dateTo ? moment(dateRange.dateTo).format('YYYY-MM-DD') : null
        },
        queryParamsHandling: 'merge'
      });
      this.loadMetrics(
        dateRange.dateFrom ? moment(dateRange.dateFrom).format('YYYY-MM-DD') : null,
        dateRange.dateTo ? moment(dateRange.dateTo).format('YYYY-MM-DD') : null
      );
    }
  }

  loadMetrics(dateFrom?: string, dateTo?: string) {
    this.isLoading = true;
    this.dashboardService.getWeeklyBarchartValues(this.cardId, this.dashboardItem.id, dateFrom, dateTo)
      .subscribe(
        data => this.prepareTableData(data as MetricGroupAssignmentType[]),
        () => this.isLoading = false
      );
  }

  prepareTableData(metricGroups: MetricGroupAssignmentType[]) {
    const values = metricGroups.map(group => group.metricGroup.metricvalueSet.edges.map(value => value.node)).flat();
    const sorted = this.sortItemsByDate(values);
    this.title = metricGroups[0].metricGroup.title;
    this.sortItemsCatch = sorted;
    this.generateWeeklySummary(sorted);
    this.isLoading = false;
  }

  generateWeeklySummary(items: MetricValueType[]) {
    let dataByWeeks: { idx: number, value: number }[] = [];

    items.forEach(item => {
      const weekNumber = this.weeksInYear(item.date);
      const obj = dataByWeeks.find(week => week.idx === weekNumber);
      if (obj) {
        obj.value = obj.value + item.value;
      } else {
        dataByWeeks.push({ idx: weekNumber, value: item.value });
      }
    });
    const weeksFromSelectedRange = this.getWeekNumbersBetweenDates(this.filterCache.dateRange.dateFrom, this.filterCache.dateRange.dateTo);

    dataByWeeks = weeksFromSelectedRange.map(weekKey => {
      const item = dataByWeeks.find(weekItem => weekItem.idx === weekKey);
      if (item) {
        return item;
      } else {
        return { idx: weekKey, value: 0 };
      }
    });

    this.chart = {
      datasets: [
        {
          label: this.translate.instant('GOAL'),
          data: dataByWeeks.map(() => this.barchartGoal),
          type: 'line',
          borderColor: '#c80000',
          backgroundColor: '#c80000',
          datalabels: {
            display: false
          }
        },
        {
          label: this.title,
          data: dataByWeeks.map(item => item.value),
          backgroundColor: '#004c60',
          datalabels: {
            anchor: 'end',
            align: 'end',
            font: {
              size: 14,
            }
          }
        }
      ],
      labels: dataByWeeks.map(item => item.idx),
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom'
          }
        }
      }
    } as unknown as BaseChartDirective;
  }

  weeksInYear(date) {
    return moment(date, moment.ISO_8601).isoWeek();
  }

  getWeekNumbersBetweenDates(dateFrom, dateTo) {
    const start = moment(dateFrom, moment.ISO_8601).startOf('isoWeek');
    const end = moment(dateTo, moment.ISO_8601).endOf('isoWeek');
    const weekNumbers = [];
    const currentDate = start.clone();

    while (currentDate.isBefore(end) || currentDate.isSame(end)) {
      weekNumbers.push(currentDate.isoWeek());
      currentDate.add(1, 'week');
    }

    return weekNumbers;
  }

  sortItemsByDate(items: any[]): any[] {
    return items.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA.getTime() - dateB.getTime();
    });
  }

  saveBarchartGoal() {
    this.dashboardService.updateDashboardCard({
      id: this.barchartCardId,
      barchartGoal: this.barchartGoal
    }).subscribe(() => this.barchartGoalCatch = this.barchartGoal, () => {});
  }

  protected readonly orderTypes = OrderTypeEnum;
}

<div fxLayout="column"fxLayoutGap="24px" fxLayoutAlign="center center">
  <div class="head" fxLayout="row" fxLayoutGap="48px" fxLayoutAlign="space-between center">
    <div>{{ 'ASSIGN_USERS' | translate }}</div>
    <mat-icon mat-dialog-close>close</mat-icon>
  </div>

  <app-popup-select-complete (changed)='onSelectedProfilesChange($event)'></app-popup-select-complete>

  <mat-dialog-actions style='justify-content: end; align-content: center'>
    <button
      mat-flat-button
      class='rounded-btn'
      color='accent'
      [disabled]='!selectedUsers.length || !data.length'
      (click)='onSubmit()'
    >{{ 'SAVE' | translate }}</button>
  </mat-dialog-actions>
</div>

<div class="mat-elevation-z0">
  <div class='head' fxLayout fxLayoutAlign='space-between center' fxLayoutGap='12px'>
    <div class="head-text">{{ 'CANDIDATES_TABLE' | translate }}</div>
    <mat-icon (click)='closeDialog()'>close</mat-icon>
  </div>

  <div class="progress-bar-wrapper">
    <mat-progress-bar *ngIf="dataSource?.loading | async" [mode]="'indeterminate'"></mat-progress-bar>
  </div>

  <mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="candidate">
      <mat-header-cell *matHeaderCellDef>{{ 'CANDIDATE' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let node">
        <div class="candidate-item">
          <a
            *ngIf="node.employeeLivasId"
            href="{{ regionService.configureBaseUrlByRegion('lt') }}personalas.php?id={{ node.employeeLivasId }}"
            target='_blank'
          >
            <mat-icon svgIcon="engineering"></mat-icon>
          </a>

          <mat-icon *ngIf="!node.employeeLivasId" svgIcon="engineering" [style.color]="'gray'"></mat-icon>

          <ng-container *ngIf="node.livasId; else candidateNoLink">
            <a
              href="{{ regionService.configureBaseUrlByRegion('lt') }}interviu.php?id={{ node.livasId }}"
              target='_blank'
            >
              {{ node.firstName }} {{ node.lastName }}
            </a>
          </ng-container>

          <ng-template #candidateNoLink>
            <span>{{ node.firstName }} {{ node.lastName }}</span>
          </ng-template>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef>{{ 'STATUS' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let node"><div class="text-container">{{ node.livasStatus?.key || '' }}</div></mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

  <mat-paginator
    [length]="dataSource?.totalCount"
    [pageIndex]="0"
    [pageSize]="50"
    [pageSizeOptions]="[10, 25, 50, 100]"
    #paginator
  ></mat-paginator>
</div>

import { Component, AfterViewChecked, ChangeDetectorRef, ViewChild } from '@angular/core';

import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi } from '@fullcalendar/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { AbsencesDialogComponent } from '../absences-dialog/absences-dialog.component';
import { AbsencesService } from '../absences.service';
import { CalendarService } from '../../calendar/calendar.service';
import { TranslateService } from '@ngx-translate/core';

import tippy from 'tippy.js';
import moment from 'moment';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

@Component({
  selector: 'app-absences-calendar',
  templateUrl: './absences-calendar.component.html',
  styleUrls: ['./absences-calendar.component.scss']
})

export class AbsencesCalendarComponent implements AfterViewChecked {
  isLoading = false;
  records = {};

  employee: any;
  absences: any;
  employers = [];
  employerId: any;
  events: Array<any>;
  employeeId: number;
  defaultEmployerId: number;
  unusedVacations: number;

  calendarApi: any;
  calendarForm: FormGroup;
  absenceForm: FormGroup;
  employeeName: string;
  calendarEvents = [];

  calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: 'prev,next',
      center: 'title',
      right: ''
    },
    plugins: [dayGridPlugin, interactionPlugin],
    initialView: 'dayGridMonth',
    weekends: true,
    eventOrder: 'employer_id',
    editable: false,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    fixedWeekCount: false,
    height: 640,
    locale: 'lt',
    firstDay: 1,
    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    datesSet: this.datesSet.bind(this),
    dayCellContent: (arg) => {
      const data = this.records[moment(arg.date).format('YYYY-MM-DD')] || {};
      const employerId = this.calendarForm.get('employerId').value;
      const shift = data.shifts && (!employerId || data.shifts[employerId]) ? `<div class="material-icons-outlined">today</div>` : '<div></div>';
      const date = `<div>${arg.dayNumberText}</div>`;

      return { html: `${shift} ${date}` };
    },
    eventContent: (arg) => {
      const props = arg.event.extendedProps;
      const title = `<div>${arg.event.title}</div>`;
      const shift = props.shifts ? `<div class="material-icons-outlined">today</div>` : '';
      const paid = props.paidUI ? `<div class="material-icons-outlined">euro</div>` : '';

      return { html: `${title} ${paid} ${shift}` };
    },
    eventDidMount: (arg) => {
      const props = arg.event.extendedProps;
      const element = arg.el;

      if (props.tooltip) {
        tippy(element, {
          content: props.tooltip,
          duration: 0,
          placement: 'bottom',
          delay: [0, 0],
        });
      }
    }
  };

  constructor(
    private fb: FormBuilder,
    private absencesService: AbsencesService,
    private calendarService: CalendarService,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog
  ) {

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.locale) {
        const locale = params.locale === 'ee' ? 'et' : params.locale;
        this.calendarOptions.locale = locale;
      }

      this.employeeId = params.employeeId;
      this.defaultEmployerId = params.employerId ? parseInt(params.employerId, 10) : 0;
    });

    this.employeeName = '';

    this.calendarForm = fb.group({
      employerId: this.employers.length === 1 ? this.employers[0].value : 0,
    });

    this.absenceForm = fb.group({
      id: null,
      employeeId: 0,
      employerId: 0,
      from: null,
      to: null,
      unpaid: false,
      absence_type: '',
      reason_code: '',
      reason_text: '',
    });

    if (!this.employeeId) {
      return;
    }

    absencesService.getEmployee(this.employeeId).subscribe(res => {
      this.employee = res;
      this.employeeName = `${this.employee.name} ${this.employee.surname}`;
    });
    absencesService.getUnusedVacations([this.employeeId], null, this.defaultEmployerId || this.calendarForm.get('employerId').value).subscribe(res => {
      this.unusedVacations = res[0].unsedVacations;
    });

    this.calendarForm.get('employerId').valueChanges.subscribe(employerId => {
      this.calendarOptions.events = [
        ...this.calendarEvents,
        ...employerId ? this.events.filter((e: any) => e.employerId === employerId) : this.events,
      ];
    });

    this.initiateForm();
  }

  async initiateForm() {
    this.employers = await this.absencesService.getEmployers();
  }

  getTooltip(absence: any) {
    if (absence.is_fixed !== false && absence.has_contract) {
      return;
    }

    const res = absence.is_fixed === false ? this.translate.instant('ABSENCE_IS_MISSING_DATA') : '';

    if (absence.has_contract) {
      return res;
    }

    return `${res ? `${res}. ` : ''}${this.translate.instant('ABSENCE_IS_MISSING_CONTRACT')}`;
  }

  getErrorMessage(res: any) {
    return `${this.translate.instant('ERRORS ACCURED')}: ${res.data_errors ? res.data_errors.map((e: any) => e.message).join(' ') : res.message ? res.message : ''}`;
  }

  getCalendarEvents(from: string, to: string, updatedEmployerId?: number) {
    this.isLoading = true;

    this.absencesService.getRecords([ this.employeeId ], from, to).subscribe(res => {
      const results = res?.results?.rows?.[this.employeeId] || {};
      this.events = [];

      this.records = {
        ...this.records,
        ...results,
      };

      for (const date of Object.keys(this.records)) {
        const record = this.records[date];

        if (!record.absences) {
          continue;
        }

        for (const employerId of Object.keys(record.absences)) {
          const absences = record.absences[employerId];
          const shifts = record.shifts ? record.shifts[employerId] : null;

          absences.forEach(absence => {
            const abs = (this.absences || []).find(a => a.value === absence.absence_type) || {};

            this.events.push({
              id: absence.id,
              employerId: parseInt(employerId, 10),
              title: abs.title || absence.absence_type,
              absence_type: absence.absence_type,
              start: date,
              classNames: [
                'absence',
                parseInt(employerId, 10) === 2 ? 'absence--theme-secondary' : 'absence--theme-primary',
                !absence.is_fixed ? 'absence--missing-data' : '',
                !absence.has_contract ? 'absence--missing-contract' : '',
              ],
              unpaid: absence.unpaid,
              paidUI: !absence.unpaid && !!abs.payable,
              shifts: shifts ? true : false,
              reason_code: absence.reason_code,
              reason_text: absence.reason_text,
              tooltip: this.getTooltip(absence),
            });
          });
        }
      }

      const listEmployerId = this.calendarForm.value.employerId;

      this.calendarOptions.events = [
        ...this.calendarEvents,
        ...(listEmployerId ? this.events.filter((e: any) => e.employerId === listEmployerId) : this.events)
      ];

      if (listEmployerId && updatedEmployerId && listEmployerId !== updatedEmployerId) {
        this.calendarForm.patchValue({
          employerId: 0,
        });
      }

      this.isLoading = false;
    });
  }

  createAbsence(form: any, shifts: Array<any>, periodAbsences?: Array<any>) {
    this.isLoading = true;

    const employerId = form.employerId;
    const from = moment(form.from).format('YYYY-MM-DD');
    const to = moment(form.to).format('YYYY-MM-DD');

    const data = { absences: [] };

    if (periodAbsences) {
      for (const date in periodAbsences) {
        if (periodAbsences[date]) {
          data.absences.push({
            employee_id: this.employeeId,
            from: date,
            to: date,
            unpaid: periodAbsences[date].unpaid,
            absence_type: form.absence_type,
            reason_code: form.reason_code,
            reason_text: form.reason_text,
            shifts,
          });
        }
      }
    }

    this.absencesService.sendAbsenceForm(employerId, data).subscribe(res => {
      if (res.status === 'success') {
        this.getCalendarEvents(from, to, employerId);

        this.snackBar.open(
          this.translate.instant(form.id ? 'ABSENCE UPDATED SUCCESSFULLY' : 'ABSENCE CREATED SUCCESSFULLY'),
          this.translate.instant('CLOSE'), {
            duration: 5000,
          }
        );
      } else if (res.status === 'error') {
        this.isLoading = false;

        const message = this.getErrorMessage(res);

        this.snackBar.open(message, this.translate.instant('CLOSE'), {
          duration: 5000,
        });
      }
    });
  }

  deleteAbsence(form: any, shiftsAction: boolean) {
    this.isLoading = true;

    this.absencesService.deleteAbsence(this.employeeId, { absences_ids: [form.id], shifts_action: shiftsAction }).subscribe(res => {
      if (res.status === 'success') {
        this.getCalendarEvents(form.from, form.to);

        this.snackBar.open(this.translate.instant('ABSENCE DELETED SUCCESSFULLY'), this.translate.instant('CLOSE'), {
          duration: 5000,
        });
      } else if (res.status === 'error') {
        this.isLoading = false;

        const message = this.getErrorMessage(res);

        this.snackBar.open(message, this.translate.instant('CLOSE'), {
          duration: 5000,
        });
      }
    });
  }

  openDialog({ title, headline, absenceForm }) {
    const dialog = this.dialog.open(AbsencesDialogComponent, {
      data: {
        title,
        headline,
        absenceForm,
        absences: this.absences,
        employers: this.employers,
        records: this.records,
        showVacations: true
      },
      width: '1200px',
      autoFocus: false,
    });

    dialog.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }

      if (res.event === 'create') {
        this.createAbsence(res.form, res.shifts, res.periodAbsences);
      } else if (res.event === 'delete') {
        this.deleteAbsence(res.form, res.shiftsAction);
      }
    });
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    const calendarApi = selectInfo.view.calendar;

    const absence = this.absences[0] || {};
    const employer = this.employers[0] || {};
    const data = this.calendarForm.getRawValue();

    const employerId = data.employerId ? data.employerId :
      this.defaultEmployerId && this.employers.find(e => e.value === this.defaultEmployerId) ? this.defaultEmployerId :
        employer.value ? employer.value : null;

    const absenceForm  = this.fb.group({
      id: null,
      employeeId: this.employeeId,
      employerId,
      from: moment(selectInfo.start).format('YYYY-MM-DD'),
      to: moment(selectInfo.end).subtract(1, 'days').format('YYYY-MM-DD'),
      unpaid: false,
      absence_type: absence.value || '',
      reason_code: '',
      reason_text: '',
    });

    this.openDialog({
      title: this.translate.instant('CREATE ABSENCE'),
      headline: this.employeeName,
      absenceForm,
    });

    calendarApi.unselect();
  }

  handleEventClick(clickInfo: EventClickArg) {
    const eventId = parseInt(clickInfo.event.id, 10);
    const event = this.events.find(e => e.id === eventId);

    if (!event) {
      return;
    }

    const absenceForm  = this.fb.group({
      id: event.id,
      employeeId: this.employeeId,
      employerId: event.employerId,
      from: event.start,
      to: event.start,
      unpaid: event.unpaid,
      absence_type: event.absence_type,
      reason_code: event.reason_code || '',
      reason_text: event.reason_text || '',
    });

    this.openDialog({
      title: this.translate.instant('EDIT ABSENCE'),
      headline: this.employeeName,
      absenceForm,
    });
  }

  datesSet(dateInfo: any) {
    this.isLoading = true;

    this.calendarOptions.events = [];

    this.absencesService.getAbsences().subscribe(res => {
      this.absences = res;

      const from = moment(dateInfo.start).format('YYYY-MM-DD');
      const to = moment(dateInfo.end).format('YYYY-MM-DD');

      this.calendarService.getCalendarDaysInPeriod(from, to).subscribe(res => {

      const calendarEvents = [];

      for (const day in res) {
        if (res[day]) {
          calendarEvents.push({
            start: day,
            allDay: true,
            display: 'background',
            color: this.calendarService.getTypeColor(res[day]),
          });
        }
      }

      this.calendarEvents = calendarEvents;

      this.getCalendarEvents(from, to);
    });
    });
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }
}
